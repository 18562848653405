import structuredClone from 'core-js/stable/structured-clone';
import {
  Checkbox,
  ChoiceGroup,
  CommandButton,
  Dropdown,
  Label,
  Link,
  mergeStyleSets, MessageBarType, Stack, Text, TextField,
} from '@fluentui/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import PickImageControl from '../../PickImageControl';
import ConfigReducer from '../../../../shared/state/ConfigReducer';
import Store from '../../../../shared/state/Store';
import ChartDefaults from '../../../../shared/ChartDefaults';
import HelpButton from '../../../../shared/help/HelpButton';
import Utils from '../../../../shared/Utils';
import TableData from '../../../../shared/npt/TableData';
import Table from '../../../../shared/npt/Table';
import Theme from '../../../../shared/Theme';
import SemanticTableEditor from './SemanticTableEditor';
import StackTokens from '../../../../shared/StackTokens';
import MessageBar from '../../../../shared/MessageBar';

const componentStyles = mergeStyleSets({

  itemCard: {
    // margin: '5px',
    padding: '5px',
    border: '1px solid rgb(237, 235, 233)',
    width: '400px',
    selectors: {
      ':first-of-type': {
        // marginLeft: '0',
        // paddingLeft: '0',
      },
      ':last-of-type': {
        // marginRight: '0',
        // paddingRight: '0',
      },
    },
  },

  headerSection: {
    maxWidth: '195px',
  },

  headerSpan: {
    width: '80px',
    display: 'inline-block',
  },

  iconPicker: {
    marginLeft: '10px !important',
    selectors: {
      '> div:last-of-type': {
        minWidth: '130px',
      },
    },
  },

  tableContainer: {
    maxWidth: '100%',
    overflowX: 'auto',
  },

  questionList: {
    marginTop: '0px !important',
    listStyle: 'none',
    paddingLeft: '5px',
    maxWidth: '100%',
    overflowX: 'auto',
  },

  questionAddButton: {
    height: '30px',
    paddingLeft: '0px',
    selectors: {
      i: {
        marginLeft: '0px',
      },
    },
  },

  questionDeleteButton: {
    height: 'fit-content',
    marginTop: '3px',
    selectors: {
      i: {
      },
    },
  },

  inputModeChoiceGroup: {
    selectors: {
      'div:not(:first-of-type)>div>label': {
        marginTop: '0px !important',
      },
      'div:not(:last-of-type)>div>label': {
        marginBottom: '0px !important',
      },
    },
  },
});

export default ({ groupName, groupItem }) => {
  const [globalState, dispatch] = Store.useStore();

  const key = Utils.createNetworkNodeKey(groupItem.network, groupItem.node);

  const nodeData = ConfigReducer.getNetworksNodesMap(globalState)[key];
  const availableInputModes = ChartDefaults.getAvailableInputModes(nodeData);

  // To account for apps created before v0.7.2
  const inputMode = groupItem.inputMode || ((groupItem.type === 'text') ? 'numericInput' : 'dropdown');
  const availableInputModeDropdown = availableInputModes.find((el) => el.key === 'dropdown');
  if (availableInputModeDropdown) {
    availableInputModeDropdown.disabled = (!groupItem.options || !groupItem.options.map || groupItem.options.length === 0);
  }

  const networkObject = ConfigReducer.getModel(globalState).networks.find((net) => net.id === groupItem.network);

  const [isOpenSemanticTableEditor, setIsOpenSemanticTableEditor] = React.useState(false);
  const [semanticTableEditorInitialSelection, setSemanticTableEditorInitialSelection] = React.useState();

  // const [isOpenSemanticUserInstructionsEditor, setIsOpenSemanticUserInstructionsEditor] = React.useState(false);

  return (
    <Stack className={componentStyles.itemCard} key={key} vertical tokens={StackTokens.spacing}>
      <Stack horizontal>
        <Stack vertical grow className={componentStyles.headerSection}>
          <Label>
            <span className={componentStyles.headerSpan}>Input Group </span>
            :
            {' '}
            {groupName}
          </Label>

          <Label>
            <span className={componentStyles.headerSpan}>Network </span>
            :
            {' '}
            {groupItem.network}
          </Label>
          <Label>
            <span className={componentStyles.headerSpan}>Node </span>
            :
            {' '}
            {groupItem.node}
          </Label>

        </Stack>

        <PickImageControl
          className={componentStyles.iconPicker}
          buttonText="Set Icon"
          width="80px"
          height="80px"
          fileSizeLimit={262144}
          imageUrl={groupItem.icon}
          setImageUrl={(imageUrl) => {
            const updatedItem = groupItem;
            updatedItem.icon = imageUrl;
            dispatch(ConfigReducer.updateInputItem(updatedItem));
          }}
        />

      </Stack>

      <Stack horizontal tokens={StackTokens.spacing}>

        <Stack.Item grow>
          <TextField
            label="Label"
            value={groupItem.label}
            placeholder="Label to display"
            onChange={(event, value) => {
              const updatedItem = groupItem;
              updatedItem.label = value;
              dispatch(ConfigReducer.updateInputItem(updatedItem));
            }}
          />
        </Stack.Item>

        <Stack.Item grow>
          <Dropdown
            label="Input Type"
            disabled={availableInputModes.length < 2}
            selectedKey={inputMode}
            options={availableInputModes}
            onChange={(e, item) => {
              const updatedItem = groupItem;
              updatedItem.inputMode = item.key;
              dispatch(ConfigReducer.updateInputItem(updatedItem));
            }}
          />
        </Stack.Item>

      </Stack>

      <Stack horizontal>
        <Checkbox
          label="Prevent stretching"
          checked={!!groupItem.preventStretch || !!groupItem.width}
          disabled={groupItem.width}
          onChange={(ev, checked) => {
            const updatedItem = groupItem;
            if (checked) {
              updatedItem.preventStretch = true;
            } else {
              delete updatedItem.preventStretch;
            }
            dispatch(ConfigReducer.updateInputItem(updatedItem));
          }}
        />
        <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Prevent Stretching">
          <Text block>Input items will try to fill up the window horizontally in one line and will spill on the next line if screen width is not enough.</Text>
          <Text block>If this item is last on the line, prevent it stretching to the edge of the window.</Text>
        </HelpButton>
      </Stack>

      {
        (nodeData.configuration.table.type === 'Manual') && (
          <>
            <Text className={Theme.styles.bold}>Input Modes</Text>
            <div>
              <Checkbox
                label="Display question based on node states"
                // If groupItem.inputModeObservation is undefined, treat it as enabled
                checked={groupItem.inputModeObservation === true || groupItem.inputModeObservation === undefined}
                onChange={(ev, checked) => {
                  const updatedItem = structuredClone(groupItem);
                  updatedItem.inputModeObservation = checked;
                  dispatch(ConfigReducer.updateInputItem(updatedItem));
                }}
              />

              <Checkbox
                label="Display question based on node probability table (NPT)"
                checked={groupItem.inputModeNpt}
                onChange={(ev, checked) => {
                  const updatedItem = structuredClone(groupItem);
                  if (checked) {
                    // Default option: whole table
                    updatedItem.inputModeNpt = 'allowWholeNptEditing';
                    // Table data is required for all options
                    updatedItem.editableNPT = TableData.build(networkObject, nodeData);
                  } else {
                    delete updatedItem.inputModeNpt;
                    delete updatedItem.editableNPT;
                    delete updatedItem.semanticTableEditing;
                  }
                  dispatch(ConfigReducer.updateInputItem(updatedItem));
                }}
              />

              { !groupItem.inputModeNpt && groupItem.inputModeObservation === false && (
                <MessageBar
                  messageBarType={MessageBarType.severeWarning}
                  text="Please enable at least one input mode"
                />
              )}

              { groupItem.inputModeNpt && (
              <ChoiceGroup
                className={componentStyles.inputModeChoiceGroup}
                selectedKey={groupItem.inputModeNpt}
                onChange={(ev, option) => {
                  const updatedItem = structuredClone(groupItem);
                  updatedItem.inputModeNpt = option.key;

                  if (option.key === 'allowWholeNptEditing') {
                    // Reset table if whole table option is selected
                    delete updatedItem.editableNPT;
                    updatedItem.editableNPT = TableData.build(networkObject, nodeData);
                    // Keep the questions if defined so the user does not lose them accidentally
                  }

                  dispatch(ConfigReducer.updateInputItem(updatedItem));
                }}
                options={[
                  {
                    key: 'allowWholeNptEditing',
                    text: (
                      <>
                        <span>Display full NPT for editing</span>
                        <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Full NPT editing">
                          <Text block>If enabled, the end-user would be able to edit prior and conditional probabilities backing this input.</Text>
                        </HelpButton>
                      </>
                    ),
                  },
                  {
                    key: 'allowSemanticNptEditing',
                    text: (
                      <>
                        <span>Display subset of NPT for editing</span>
                        <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Semantic NPT editing">
                          <div style={isMobile ? {} : {
                            maxWidth: '45vw',
                          }}
                          >
                            <Text block>You can allow your user to partially override underlying node probability table by answering questions that each override a single cell in the table.</Text>
                            <Text block>You can create questions for as many cells as you like.</Text>
                            <Text block>When the user enters a value, the following rules are applied to all values associated with the same table column:</Text>
                            <Text block>• values entered must be between 1e-30 and 1e30</Text>
                            <Text block>• empty or NaN values are replaced with default values (as defined in the model or app configuration)</Text>
                            <Text block>• if sum of overridden values &lt; 1, the remainder is equally divided between non-overridden cells</Text>
                            <Text block>• if strict  mode is enabled, sum of overridden values can not be &gt; 1, and if it is = 1, non-overridden cells will be set to 0</Text>
                            { /* eslint-disable-next-line max-len */ }
                            <Text block>• if strict  mode is disabled, and sum of overridden values is &gt;= 1, the non-overridden cells will be set to near-zero value and the column will then be normalised</Text>
                          </div>
                        </HelpButton>
                      </>
                    ),
                  },
                ]}
                label=""
              />
              )}
            </div>
          </>
        )
      }

      {
        groupItem.inputModeNpt === 'allowWholeNptEditing' && groupItem.editableNPT && (
          <div className={componentStyles.tableContainer}>
            <Text block>Edit the labels of states and parent states below:</Text>
            <Table
              data={groupItem.editableNPT}
              updateTable={(updatedTableData) => {
                const updatedItem = groupItem;
                updatedItem.editableNPT = updatedTableData;
                dispatch(ConfigReducer.updateInputItem(updatedItem));
              }}
              editableHeaders
              editableValues
              // className={componentStyles.table}
            />
          </div>
        )
      }

      { groupItem.inputModeNpt === 'allowSemanticNptEditing'
       && (!groupItem.semanticTableEditing || groupItem.semanticTableEditing.length === 0) && (
       <MessageBar
         messageBarType={MessageBarType.severeWarning}
         text="Please define at least one question"
       />
      )}

      { groupItem.inputModeNpt === 'allowSemanticNptEditing' && (
        <>
          <Checkbox
            label="Display questions as individual form entry items"
            checked={groupItem.semanticQuestionnaireSpread}
            onChange={(ev, checked) => {
              const updatedItem = groupItem;
              if (checked) {
                updatedItem.semanticQuestionnaireSpread = true;
              } else {
                delete updatedItem.semanticQuestionnaireSpread;
              }
              dispatch(ConfigReducer.updateInputItem(updatedItem));
            }}
          />

          <Checkbox
            label="Hide probabilities for non-overridden cells"
            checked={groupItem.semanticEditingHideNonOverridden}
            onChange={(ev, checked) => {
              const updatedItem = groupItem;
              if (checked) {
                updatedItem.semanticEditingHideNonOverridden = true;
              } else {
                delete updatedItem.semanticEditingHideNonOverridden;
              }
              dispatch(ConfigReducer.updateInputItem(updatedItem));
            }}
          />

          <Checkbox
            label={(
              <>
                <Stack horizontal tokens={StackTokens.spacing5}>
                  <Text>Strict input validation</Text>
                  <HelpButton styles={{ root: { marginLeft: '5px' } }} title="Strict input validation">
                    <div style={isMobile ? {} : {
                      maxWidth: '45vw',
                    }}
                    >
                      <Text block>When enabled:</Text>
                      <Text block>• all values entered by the user must add up to 1 if associated with the same column</Text>
                      <Text block>• if overridden column entries add up to 1, non-overridden cells will be set to 0</Text>
                      <Text block style={{ marginTop: '5px' }}>When disabled:</Text>
                      <Text block>• overridden column entries can add up to &gt;= 1, in which case the non-overridden cells will be set to near-zero value and the column will then be normalised</Text>
                    </div>
                  </HelpButton>
                </Stack>
              </>
            )}
            checked={groupItem.semanticEditingStrict}
            onChange={(ev, checked) => {
              const updatedItem = groupItem;
              if (checked) {
                updatedItem.semanticEditingStrict = true;
              } else {
                delete updatedItem.semanticEditingStrict;
              }
              dispatch(ConfigReducer.updateInputItem(updatedItem));
            }}
          />
        </>

      )}

      {
        (groupItem.inputModeNpt === 'allowSemanticNptEditing') && (
          <Stack vertical>
            <Stack horizontal styles={{ root: { marginTop: '0px !important' } }}>
              <CommandButton
                text="Add a cell override question"
                iconProps={{
                  iconName: 'Add',
                }}
                className={[Theme.styles.outlineControlButton, Theme.styles.commandButton, Theme.styles.iconButtonClearMinimalistic, componentStyles.questionAddButton].join(' ')}
                onClick={async () => {
                  setSemanticTableEditorInitialSelection();
                  setIsOpenSemanticTableEditor(true);
                }}
              />
              <SemanticTableEditor
                tableData={groupItem.editableNPT || TableData.build(networkObject, nodeData)}
                setTableData={(tableData) => {
                  if (groupItem.editableNPT) {
                    return;
                  }
                  const updatedItem = structuredClone(groupItem);
                  updatedItem.editableNPT = tableData;
                }}
                isOpen={isOpenSemanticTableEditor}
                setIsOpen={setIsOpenSemanticTableEditor}
                inputConfig={groupItem}
                updateInputConfig={(updatedConfig) => {
                  dispatch(ConfigReducer.updateInputItem(updatedConfig));
                }}
                initialSelection={semanticTableEditorInitialSelection}
              />
            </Stack>

            <ol className={componentStyles.questionList}>
              {
                  groupItem.semanticTableEditing?.sort((q1, q2) => q1.col - q2.col + (q1.row - q2.row) / 10).map((questionData) => (
                    <li key={questionData.row + questionData.col}>
                      <Stack horizontal verticalAlign="start">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                          className={Theme.styles.outlineControlButton}
                          onClick={() => {
                            setSemanticTableEditorInitialSelection({ cell1: { row: questionData.row, col: questionData.col }, singleCell: true });
                            setIsOpenSemanticTableEditor(true);
                          }}
                        >
                          {`[${questionData.col + 1}, ${questionData.row + 1}]: ${questionData.question}`}
                        </Link>

                        <CommandButton
                          iconProps={{
                            iconName: 'Delete',
                          }}
                          title={`Delete question ${questionData.col + 1}`}
                          className={[Theme.styles.outlineControlButton, Theme.styles.commandButton, Theme.styles.iconButtonClearMinimalistic, componentStyles.questionDeleteButton].join(' ')}
                          onClick={async () => {
                            const updatedConfig = { ...groupItem };
                            const qIndex = updatedConfig.semanticTableEditing
                              .findIndex((q) => q.row === questionData.row && q.col === questionData.col);
                            if (qIndex >= 0) {
                              updatedConfig.semanticTableEditing.splice(qIndex, 1);
                            }
                            dispatch(ConfigReducer.updateInputItem(updatedConfig));
                          }}
                        />
                      </Stack>
                    </li>
                  ))
                }
            </ol>
          </Stack>
        )
       }

    </Stack>
  );
};
