import React from 'react';
import {
  DefaultButton, mergeStyleSets, Separator, Stack,
} from '@fluentui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import Store from '../shared/state/Store';
import Theme from '../shared/Theme';
import Routing from '../shared/Routing';

export const DefaultNavOption = Routing.getTree({}).home.routes.administration.routes.settings.routes.reservedDomains.path;

const styles = mergeStyleSets({
  menuItem: {
    textAlign: 'left',
    border: 'none !important',
    whiteSpace: 'nowrap',
    selectors: {
      ':hover': {
        outline: 'none !important',
        backgroundColor: '#e4e4e4',
      },

      span: {
        fontWeight: 'normal !important',
      },
    },
  },

  menuItemSelected: {
    backgroundColor: '#e4e4e4',
  },
});

export default () => {
  const [globalState] = Store.useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const routingTree = Routing.getTree(globalState).home.routes.administration.routes;

  const menuItems = [
    {
      type: 'subHeader',
      text: 'Settings',
    },
    {
      type: 'button',
      text: routingTree.settings.routes.reservedDomains.text,
      key: routingTree.settings.routes.reservedDomains.path,
      action: () => navigate(routingTree.settings.routes.reservedDomains.path),
    },
    {
      type: 'subHeader',
      text: 'Data',
    },
    {
      type: 'button',
      text: routingTree.archive.text,
      key: routingTree.archive.path,
      action: () => navigate(routingTree.archive.path),
    },
  ];

  const selectedMenuItem = location.pathname || DefaultNavOption;

  const renderedItems = menuItems.map(((menuItemData) => {
    if (menuItemData.type === 'button') {
      let className = styles.menuItem;
      if (menuItemData.key === selectedMenuItem) {
        className += ` ${styles.menuItemSelected}`;
      }
      return (
        <DefaultButton
          key={menuItemData.key}
          text={menuItemData.text}
          className={className}
          onClick={(event) => {
            menuItemData.action();
            event.target.closest('button').blur();
          }}
        />
      );
    }
    if (menuItemData.type === 'subHeader') {
      return (
        <div key={menuItemData.text}>
          <header className={Theme.styles.menuSubHeader}>{menuItemData.text}</header>
          <Separator horizontal styles={Theme.styleObjects.separatorSmallMargin} />
        </div>
      );
    }
    return <></>;
  }));

  return (
    <Stack vertical>
      {renderedItems}
    </Stack>
  );
};
