import { Dropdown } from '@fluentui/react';
import React from 'react';
import ModellerReducer from '../ModellerReducer';
import Store from '../../shared/state/Store';
import Utils from '../../shared/Utils';
import GraphDefaults from '../ModellerDefaults';
import GraphUtils from '../GraphUtils';
import TextFieldBuffered from '../../shared/TextFieldBuffered';

const componentStylesRaw = {
  label: {
    subComponentStyles: {
      label: {
        root: {
          whiteSpace: 'nowrap',
          overflow: 'visible',
          maxWidth: '100px',
        },
      },
    },
  },
};

export default ({
  nodeConfig, networkId, datasetId, hideLabel, tabIndexValue,
}) => {
  const [globalState, dispatch] = Store.useStore();
  const graphicsConfig = nodeConfig.graphics || GraphDefaults.DefaultGraphics;

  let entrySection = <></>;
  const currentValue = (ModellerReducer.getMappedObservation(globalState, datasetId, networkId, nodeConfig.id, undefined) || {})?.entries?.[0]?.value || '';
  // const currentValue = (ModellerReducer.getObservation(globalState, datasetId, networkId, nodeConfig.id) || {})?.entries?.[0]?.value;
  const inputMode = GraphUtils.getInputMode(nodeConfig);

  if (inputMode === GraphDefaults.InputModes.NUMERIC) {
    entrySection = (
      <div key={nodeConfig.id}>
        <TextFieldBuffered
          label={hideLabel ? <>&nbsp;</> : (nodeConfig.name || nodeConfig.id)}
          placeholder={graphicsConfig.input?.placeholder}
          validateValue={(value) => {
            let validatedValue;
            try {
              validatedValue = GraphUtils.validateInputValue({
                value,
                validationRules: graphicsConfig.input?.validationRules || { allowEmpty: true, type: (nodeConfig.configuration.type === 'IntegerInterval') ? 'int' : 'float' },
              });
            } catch (error) {
              throw new Error(error.message);
            }
            return validatedValue;
          }}
          applyValue={(value) => {
            dispatch(ModellerReducer.setObservation(datasetId, networkId, nodeConfig.id, value));
          }}
          actualValue={currentValue}
          styles={componentStylesRaw.label}
          tabIndex={tabIndexValue}
        />
      </div>
    );
  } else if (inputMode === GraphDefaults.InputModes.DROPDOWN) {
    const options = (nodeConfig.configuration?.states || []).map((key) => ({ key, text: Utils.capitalize(key) }));
    entrySection = (
      <Dropdown
        label={hideLabel ? <>&nbsp;</> : (nodeConfig.name || nodeConfig.id)}
        placeholder={GraphDefaults.InputDefaultOption}
        selectedKey={currentValue}
        options={[{ key: '', text: GraphDefaults.InputDefaultOption }, ...options]}
        onChange={(event, item) => {
          dispatch(ModellerReducer.setObservation(datasetId, networkId, nodeConfig.id, item.key));
        }}
        styles={componentStylesRaw.label}
        tabIndex={tabIndexValue}
      />
    );
  }

  const variableEntries = (nodeConfig.configuration.variables || []).map((nodeVar, varIndex) => (
    <div key={nodeConfig.id + nodeVar.name}>
      <TextFieldBuffered
        label={hideLabel ? <>&nbsp;</> : `${nodeConfig.name || nodeConfig.id}: ${nodeVar.name} (variable)`}
        placeholder={`Default: ${nodeVar.value}`}
        validateValue={(value) => {
          let validatedValue;
          try {
            validatedValue = GraphUtils.validateInputValue({ value, validationRules: { allowEmpty: true, type: 'float' } });
          } catch (error) {
            throw new Error(error.message);
          }
          return validatedValue;
        }}
        applyValue={(value) => {
          dispatch(ModellerReducer.setObservation(datasetId, networkId, nodeConfig.id, value, nodeVar.name));
        }}
        actualValue={(ModellerReducer.getMappedObservation(globalState, datasetId, networkId, nodeConfig.id, nodeVar.name) || {})?.entries?.[0]?.value || ''}
        styles={componentStylesRaw.label}
        tabIndex={tabIndexValue + varIndex + 1}
      />
    </div>
  ));

  return (
    <>
      {
        entrySection
      }
      {variableEntries}
    </>
  );
};
