import ConfigReducer from './ConfigReducer';
import DataReducer from './DataReducer';
import FormReducer from './FormReducer';
import Config from '../Config';
import UiReducer from './UiReducer';

/**
 * Reducer, use in dispatch
 * Resets currently loaded cloud app data (cloud integration)
 */
const resetAppData = () => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.app = undefined;
    return updatedState;
  }),
});

/**
 * Reducer, use in dispatch
 * Sets currently loaded cloud app data (cloud integration)
 */
const setAppData = (data) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    const appJson = { ...data };

    if (appJson?.configuration) {
      delete appJson?.configuration;
    }

    updatedState.app = appJson;

    return updatedState;
  }),
});

const getAppData = (globalState) => globalState?.app;

/**
 * Reducer, use in dispatch
 * Loads the app configuration into Web App Designer, builds input and output caches
 */
const loadApp = (json) => ({
  reduce: ((prevState) => {
    const dataToLoad = { ...json };
    let updatedState = { ...prevState };

    if (!dataToLoad.generalConfig?.appViewEnabled && !dataToLoad.generalConfig?.modelViewEnabled) {
      dataToLoad.generalConfig = dataToLoad.generalConfig || {};
      dataToLoad.generalConfig.appViewEnabled = true;
    }
    updatedState = ConfigReducer.setConfig(dataToLoad).reduce(updatedState);
    if (dataToLoad.model) {
      // We may not necessarily have a model object here (in case app data was sent with only user-access)
      updatedState = ConfigReducer.mapNetworksNodes(dataToLoad.model).reduce(updatedState);
    }
    updatedState = ConfigReducer.mapInputConfig(dataToLoad.inputConfig).reduce(updatedState);
    updatedState = ConfigReducer.mapOutputConfig(dataToLoad.outputConfig).reduce(updatedState);
    updatedState = DataReducer.resetCachedResults().reduce(updatedState);
    updatedState = FormReducer.setFormData({}).reduce(updatedState);

    if (dataToLoad.resultPrecache) {
      dataToLoad.resultPrecache.forEach((dsData) => {
        updatedState = DataReducer.cacheResults(dsData.dataSet, dsData, updatedState.outputConfigMap).reduce(updatedState);
      });
    }

    updatedState = resetAppData().reduce(updatedState);
    updatedState = UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppDesignerModelModified, false).reduce(updatedState);

    if (Config.isModeWebApp()) {
      document.title = dataToLoad.generalConfig.appName;
    }

    updatedState = UiReducer.setGenericUiAttribute(UiReducer.Keys.WebbAppModelViewSelected, !dataToLoad.generalConfig?.appViewEnabled).reduce(updatedState);

    return updatedState;
  }),
});

const isAppLoaded = (globalState) => Object.keys(globalState.config?.model || {}).length > 0;

const Defaults = {
  hideOutputsMessage: 'Thank you! Your inputs have been submitted.',
};

export default {
  resetAppData,
  setAppData,
  getAppData,
  loadApp,
  isAppLoaded,
  Defaults,
};
