/**
 * Reducer, use in dispatch
 */
const setFormData = (formData) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.data = formData;
    return updatedState;
  }),
});

const getFormData = (globalState) => globalState.form?.data || {};

/**
 * Reducer, use in dispatch
 */
const setFormItem = (key, value) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.data = updatedState.form.data || {};
    updatedState.form.data[key] = value;
    return updatedState;
  }),
});

const getFormItemValue = (globalState, key) => {
  if (!globalState.form || !globalState.form?.data) {
    return undefined;
  }
  return globalState.form.data[key];
};

const isFormGroupCollapsed = (globalState, groupName) => {
  if (!globalState.form?.groupsCollapsed) {
    return undefined;
  }
  return globalState.form.groupsCollapsed[groupName];
};

/**
 * Reducer, use in dispatch
 */
const setFormGroupCollapsed = (groupName, collapsed) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.form = updatedState.form || {};
    updatedState.form.groupsCollapsed = updatedState.form.data || {};
    updatedState.form.groupsCollapsed[groupName] = collapsed;
    return updatedState;
  }),
});

export default {
  setFormData,
  getFormData,
  setFormItem,
  getFormItemValue,
  isFormGroupCollapsed,
  setFormGroupCollapsed,
};
