import { ActionButton, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import Theme from './Theme';

const defaultStyles = mergeStyleSets({
  root: {
    verticalAlign: 'top',
    display: 'inline-block',
    textAlign: 'left',
    margin: '0',
    height: 'auto',
  },
  button: {
    margin: '0',
    padding: '0',
    color: Theme.Colors.Blue,
    outline: 'none !important',
    height: 'auto',
  },
  buttonHoverUnderlineSolid: {
    selectors: {
      ':hover': {
        textDecoration: 'underline',
      },
    },
  },
  buttonHoverUnderlineDashed: {
    selectors: {
      ':hover': {
        borderBottom: `1px ${Theme.Colors.Blue} dashed`,
      },
    },
  },
});

export default ({
  styles, onClick, classes, text, hoverUnderline, className,
}) => (
  <div
    className={[className, defaultStyles.root, classes?.root].join(' ')}
    style={styles?.root}
  >
    <ActionButton
      onClick={onClick}
      text={text}
      className={[
        defaultStyles.button,
         classes?.button,
         (hoverUnderline === 'solid') ? defaultStyles.buttonHoverUnderlineSolid : undefined,
         (hoverUnderline === 'dashed') ? defaultStyles.buttonHoverUnderlineDashed : undefined,
      ].join(' ')}
      styles={{
        button: styles?.button,
        label: { margin: '0' },
      }}
    />
  </div>
);
