import Utils from '../shared/Utils';
import ModelUtils from './ModelUtils';
import ModellerDefaults from './ModellerDefaults';
import GraphUtils from './GraphUtils';

const SettingKeys = {
  tabSelected: 'tabSelected',
  tabSelectedGraph: 'tabSelectedGraph',
  tabSelectedTable: 'tabSelectedTable',

  colorByNetwork: 'colorByNetwork',

  filename: 'filename',
};


const updateModel = (model) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.model = model;
    return updatedState;
  }),
});

const loadModel = (model) => ({
  reduce: ((prevState) => {
    const modelToLoad = Utils.clone(model);
    let updatedState = prevState;
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.selectedGraphKey = ModellerDefaults.FlatModelGraphKey;
    if (model.networks?.length === 1) {
      updatedState.modeller.selectedGraphKey = GraphUtils.escapeSelector(model.networks[0].id);
    }
    updatedState.modeller.mappedGraphs = {};
    updatedState.modeller.settings = updatedState.modeller.settings || {};
    updatedState.modeller.settings[SettingKeys.tabSelected] = SettingKeys.tabSelectedGraph;
    updatedState.modeller.cachedCharts = {};

    if (!model.dataSets || model.dataSets.length === 0) {
      modelToLoad.dataSets = modelToLoad.dataSets || [];
      modelToLoad.dataSets = [{
        id: 'Case 1',
        observations: [],
        results: [],
      }];
    }
    updatedState = updateModel(modelToLoad).reduce(updatedState);
    return updatedState;
  }),
});

const getModel = (globalState) => globalState.modeller?.model;

const mapObservation = (observationData) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };

    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.mappedObservations = updatedState.modeller.mappedObservations || {};
    updatedState.modeller.mappedObservations[observationData.dataset] = updatedState.modeller.mappedObservations[observationData.dataset] || {};
    updatedState.modeller.mappedObservations[observationData.dataset][observationData.network] = updatedState.modeller.mappedObservations[observationData.dataset][observationData.network] || {};
    // eslint-disable-next-line max-len
    updatedState.modeller.mappedObservations[observationData.dataset][observationData.network][observationData.node] = updatedState.modeller.mappedObservations[observationData.dataset][observationData.network][observationData.node] || {};
    if (!observationData.entries || observationData.entries[0].value === '') {
      delete updatedState.modeller.mappedObservations[observationData.dataset][observationData.network][observationData.node][observationData.constantName];
    } else {
      updatedState.modeller.mappedObservations[observationData.dataset][observationData.network][observationData.node][observationData.constantName] = observationData;
    }

    return updatedState;
  }),
});


const setObservation = (datasetId, networkId, nodeId, value, variableName) => ({
  reduce: ((prevState) => {
    let updatedState = { ...prevState };
    updatedState.modeller = updatedState.modeller || {};

    if (!updatedState.modeller.model) {
      return updatedState;
    }

    const observationData = ModelUtils.setObservation({
      model: updatedState.modeller.model, datasetId, networkId, nodeId, value, variableName,
    });
    if (observationData) {
      observationData.dataset = datasetId;
    }

    updatedState = mapObservation(observationData).reduce(updatedState);

    return updatedState;
  }),
});

const getObservation = (globalState, datasetId, networkId, nodeId) => ModelUtils.getObservation({
  model: getModel(globalState), datasetId, networkId, nodeId,
});

const getResult = (globalState, datasetId, networkId, nodeId) => ModelUtils.getResult({
  model: getModel(globalState), datasetId, networkId, nodeId,
});

const mapResults = (model, refreshedAt) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };

    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.model = updatedState.modeller.model || model;
    updatedState.modeller.mappedResults = {};

    if (model && model.dataSets) {
      model.dataSets.forEach((dsData) => {
        const dsMapData = {};
        if (dsData.results?.length > 0) {
          dsData.results.forEach((nodeData) => {
            if (!dsMapData[nodeData.network]) {
              dsMapData[nodeData.network] = {};
            }
            dsMapData[nodeData.network][nodeData.node] = {
              dataset: dsData.id,
              refreshedAt,
              ...nodeData,
            };
          });
        }
        updatedState.modeller.mappedResults[dsData.id] = dsMapData;
      });
    }

    return updatedState;
  }),
});

const getMappedResult = (globalState, datasetId, networkId, nodeId) => globalState.modeller?.mappedResults?.[datasetId]?.[networkId]?.[nodeId];

const mapObservations = (model) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };

    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.model = updatedState.modeller.model || model;
    updatedState.modeller.mappedObservations = updatedState.modeller.mappedObservations || {};

    if (model && model.dataSets) {
      model.dataSets.forEach((dsData) => {
        const dsMapData = {};
        if (dsData.observations?.length > 0) {
          dsData.observations.forEach((observationData) => {
            if (!dsMapData[observationData.network]) {
              dsMapData[observationData.network] = {};
            }
            if (!dsMapData[observationData.network][observationData.node]) {
              dsMapData[observationData.network][observationData.node] = {};
            }
            dsMapData[observationData.network][observationData.node][observationData.constantName] = {
              dataset: dsData.id,
              ...observationData,
            };
          });
        }
        updatedState.modeller.mappedObservations[dsData.id] = dsMapData;
      });
    }

    return updatedState;
  }),
});

const getMappedObservation = (globalState, datasetId, networkId, nodeId, variableName) => globalState.modeller?.mappedObservations?.[datasetId]?.[networkId]?.[nodeId]?.[variableName];

const mapNodeToChart = (chartRef, networkId, nodeId) => ({
  reduce: ((prevState) => {
    const updatedState = prevState;
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.mappedCharts = updatedState.modeller.mappedCharts || {};
    updatedState.modeller.mappedCharts[networkId] = updatedState.modeller.mappedCharts[networkId] || {};
    updatedState.modeller.mappedCharts[networkId][nodeId] = Utils.svgToDataUri(Utils.getSvg(chartRef), networkId, nodeId);

    return updatedState;
  }),
});

const getMappedChart = (globalState, networkId, nodeId) => globalState.modeller?.mappedCharts?.[networkId]?.[nodeId];

const setSetting = (key, value) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.settings = updatedState.modeller.settings || {};
    updatedState.modeller.settings[key] = value;
    return updatedState;
  }),
});

const getSetting = (globalState, key) => globalState.modeller?.settings?.[key];

const mapGraph = (key, graph) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.mappedGraphs = { ...(updatedState.modeller.mappedGraphs || {}) };
    updatedState.modeller.mappedGraphs[key] = graph;
    return updatedState;
  }),
});

const getMappedGraph = (globalState, key) => globalState.modeller?.mappedGraphs?.[key];

const setSelectedGraphKey = (key, graph) => ({
  reduce: ((prevState) => {
    let updatedState = { ...prevState };
    if (graph) {
      updatedState = mapGraph(key, graph).reduce(updatedState);
    }
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.selectedGraphKey = key;
    return updatedState;
  }),
});

const getSelectedGraphKey = (globalState) => globalState.modeller?.selectedGraphKey;

const cacheNodeChart = (networkId, nodeId, chartData) => ({
  reduce: ((prevState) => {
    // console.log('caching chart ', networkId, nodeId);
    const updatedState = prevState;
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.cachedCharts = updatedState.modeller.cachedCharts || {};
    updatedState.modeller.cachedCharts[networkId] = updatedState.modeller.cachedCharts[networkId] || {};
    updatedState.modeller.cachedCharts[networkId][nodeId] = chartData;
    return updatedState;
  }),
});

const getCachedNodeChart = (globalState, networkId, nodeId) => globalState.modeller?.cachedCharts?.[networkId]?.[nodeId];

const cacheCalculationResponse = (datasetId, response) => ({
  reduce: ((prevState) => {
    const updatedState = { ...prevState };
    updatedState.modeller = updatedState.modeller || {};
    updatedState.modeller.cachedResponses = updatedState.modeller.cachedResponses || {};
    updatedState.modeller.cachedResponses[datasetId] = response;
    return updatedState;
  }),
});

const getCachedCalculationResponse = (globalState, datasetId) => globalState.modeller?.cachedResponses?.[datasetId];

export default {
  updateModel,
  getModel,
  setObservation,
  getObservation,
  getResult,
  mapResults,
  getMappedResult,
  mapNodeToChart,
  getMappedChart,
  setSetting,
  getSetting,
  mapGraph,
  getMappedGraph,
  setSelectedGraphKey,
  getSelectedGraphKey,
  SettingKeys,
  loadModel,
  cacheNodeChart,
  getCachedNodeChart,
  mapObservations,
  getMappedObservation,
  mapObservation,
  cacheCalculationResponse,
  getCachedCalculationResponse,
};
