import { mergeStyleSets, Stack } from '@fluentui/react';
import React from 'react';
import StackTokens from '../shared/StackTokens';
import ModellerReducer from './ModellerReducer';
import Store from '../shared/state/Store';
import GraphUtils from './GraphUtils';
import DataInputItem from './io/DataInputItem';
import ModellerDefaults from './ModellerDefaults';
import Styles from './Styles';

const componentStyles = mergeStyleSets({
  root: {
    height: `calc(${ModellerDefaults.ComponentDefaults.ComponentHeight} - 10px)`,
  },
  crossColumnHeader: {
    whiteSpace: 'nowrap',
    overflow: 'visible',
    maxWidth: '100px',
  },
});

export default ({ selectedGraphKey }) => {
  const [globalState] = Store.useStore();
  const model = ModellerReducer.getModel(globalState);

  let { networks } = model;
  if (selectedGraphKey !== ModellerDefaults.FlatModelGraphKey) {
    networks = networks.filter((network) => GraphUtils.escapeSelector(network.id) === selectedGraphKey);
  }

  const datasetColumns = model.dataSets.map((ds) => [
    <header className={Styles.headerSection} key={ds.id}>{ds.id}</header>,
  ]);
  networks.forEach((network) => {
    datasetColumns[0].push(
      <header className={[Styles.headerSubsection, componentStyles.crossColumnHeader].join(' ')} key={network.id}>
        {network.name || network.id}
      </header>,
    );
    for (let i = 1; i < model.dataSets.length; i += 1) {
      datasetColumns[i].push(<header key={network.id}>&nbsp;</header>);
    }

    network.nodes.forEach((node, nodeIndex) => {
      for (let i = 0; i < model.dataSets.length; i += 1) {
        datasetColumns[i].push(
          <DataInputItem
            key={model.dataSets[i].id + network.id + node.id}
            datasetId={model.dataSets[i].id}
            networkId={network.id}
            nodeConfig={node}
            hideLabel={i > 0}
            tabIndexValue={(nodeIndex + 1) * 100}
          />,
        );
      }
    });
  });

  return (
    <Stack horizontal tokens={StackTokens.spacingWide} className={componentStyles.root}>
      {
        datasetColumns.map((dsColumn, i) => (
          <Stack key={model.dataSets[i].id} vertical tokens={StackTokens.spacing}>
            {dsColumn}
          </Stack>
        ))
      }
    </Stack>
  );
};
