import React from 'react';
import { Stack } from '@fluentui/react';
import User from '../../shared/User';
import Theme from '../../shared/Theme';
import MessageBar from '../../shared/MessageBar';
import FeatureUsage from './FeatureUsage';
import StackTokens from '../../shared/StackTokens';
import Store from '../../shared/state/Store';

export default ({ subscriptionAccount, includeUsage }) => {
  const [globalState] = Store.useStore();
  return (
    <Stack vertical>
      <div style={{ margin: '10px 0 0 0' }}>
        <table className={[Theme.styles.table, Theme.styles.tableNoPadding, Theme.styles.tableText].join(' ')}>
          <tbody>
            <tr>
              <th>ID</th>
              <td>:</td>
              <td>{subscriptionAccount.uuid}</td>
            </tr>
            <tr>
              <th>Subscription owner</th>
              <td>:</td>
              <td>{subscriptionAccount.email}</td>
            </tr>
            <tr>
              <th>Refreshed</th>
              <td>:</td>
              <td>{new Date(subscriptionAccount.lastSynced).toLocaleString(navigator.languages[0])}</td>
            </tr>
          </tbody>
        </table>
        {(subscriptionAccount.email !== User.getCurrentUser())
        && (
        <MessageBar
          styles={{ container: { width: '650px' } }}
          text={`Your ${User.getAssignedCreatorKey(globalState) ? 'Creator' : 'API'} subscription is granted by ${subscriptionAccount.email}, so their subscription allowances apply to your account`}
        />
        )}
      </div>

      <header className={Theme.styles.sectionHeader}>Subscription Allowance</header>
      <div>
        <table className={[Theme.styles.table, Theme.styles.tableNoPadding, Theme.styles.tableText].join(' ')}>
          <tbody>
            <tr>
              <th>Apps saved to Cloud</th>
              <td>:</td>
              {includeUsage && (
              <>
                <td>{subscriptionAccount.subscription.totalCloudApps}</td>
                <td>/</td>
              </>
              )}
              <td>{subscriptionAccount.subscription.maxCloudAppsPerCreator}</td>
            </tr>
            <tr>
              <th>Published apps</th>
              <td>:</td>
              {includeUsage && (
              <>
                <td>{subscriptionAccount.subscription.totalPublishedApps}</td>
                <td>/</td>
              </>
              )}
              <td>{subscriptionAccount.subscription.maxPublishedCloudApps}</td>
            </tr>
            <tr>
              <th>Total users across all apps</th>
              <td>:</td>
              {includeUsage && (
              <>
                <td>{subscriptionAccount.subscription.totalEndUsers}</td>
                <td>/</td>
              </>
              )}
              <td>{subscriptionAccount.subscription.maxUsersTotal}</td>
            </tr>
            <tr>
              <th>Users per app</th>
              <td>:</td>
              {includeUsage && (
              <>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </>
              )}
              <td>{subscriptionAccount.subscription.maxUsersPerApp}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <header className={Theme.styles.sectionHeader}>Metered Usage</header>
      {/* <div>
      <MessageBar
        styles={{ container: { width: '650px' } }}
        text="Feature usage is metered when end-users access apps published by creators assigned to your account"
      />
    </div> */}
      <Stack styles={{ inner: { margin: '-10px 0 0 -10px' } }} horizontal wrap tokens={StackTokens.spacingWide}>
        {
        Object.values(subscriptionAccount.subscription.featuresUsage)
          .filter((featureUsage) => subscriptionAccount.subscription.featuresEnabled.includes(featureUsage.feature))
          .map((featureUsage) => <FeatureUsage featureUsage={featureUsage} key={featureUsage.feature} />)
      }
      </Stack>

    </Stack>
  );
};
