import React from 'react';
import {
  Checkbox,
  DefaultButton, mergeStyleSets, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, Text,
} from '@fluentui/react';
import PlaceholderError from '../PlaceholderError';
import StackTokens from '../StackTokens';
import ArchivingApi from '../util/ArchivingApi';
import LinkButton from '../LinkButton';
import Actions from './Actions';
import Theme from '../Theme';
import JsonViewer from './JsonViewer';
import Store from '../state/Store';
import UiReducer from '../state/UiReducer';
import Pagination from './Pagination';
import Config from '../Config';
import Data from './Data';
import MessageBar from '../MessageBar';

const componentStyles = mergeStyleSets({
  root: {
    padding: '5px',
  },
  detailsList: {
    selectors: {
      button: {
        fontSize: '12px',
        userSelect: 'auto',
      },
      '.ms-DetailsHeader': {
        paddingTop: '0 !important',
      },
      '.ms-DetailsRow-cell': {
        paddingBottom: '0 !important',
        minHeight: 'fit-content !important',
      },
    },
  },

  filterBox: {
    width: '200px',
  },
});

const typeLabelMap = {
  calculate: 'Inference',
  percentile: 'Percentiles',
  modeljson: 'Model Conversion',
  sensitivity: 'Sensitivity Analysis',
};

export default ({ fixedFilters, extraFields }) => {
  const [, dispatch] = Store.useStore();
  const [initErrors, setInitErrors] = React.useState();

  const [response, setResponse] = React.useState('');
  const [totalCount, setTotalCount] = React.useState(0);

  const [isApplyDisabled, setApplyDisabled] = React.useState(true);

  const [showDetails, setShowDetails] = React.useState(false);

  const [tableRows, setTableRows] = React.useState({});

  const [viewJson, setViewJson] = React.useState();

  const filtersDefault = {
    uuid: fixedFilters?.uuid || '',
    appId: fixedFilters?.appId || '',
    email: fixedFilters?.email || '',
    page: 1,
  };

  const [filters, setFilters] = React.useState({ ...filtersDefault });

  const [isLoading, setIsLoading] = React.useState(true);

  const updateFilters = (prop, value) => {
    const updatedFilters = { ...filters };
    updatedFilters[prop] = value || '';
    setFilters(updatedFilters);
  };

  const makeRequest = async (filtersOverride) => {
    setIsLoading(true);
    let effectiveFilters;
    if (filtersOverride) {
      effectiveFilters = filtersOverride;
    } else {
      effectiveFilters = { ...filters, page: 1 };
    }
    setFilters(effectiveFilters);

    setApplyDisabled(true);
    const params = {
      page: effectiveFilters.page - 1,
      ...(effectiveFilters.uuid && { uuid: effectiveFilters.uuid }),
      ...(effectiveFilters.appId && { appId: effectiveFilters.appId }),
      ...(effectiveFilters.email && { 'user.email': effectiveFilters.email }),
    };

    const jResponse = await ArchivingApi.getRecords(params);

    setIsLoading(false);

    if (jResponse.code >= 300) {
      if (jResponse.messages && jResponse.messages.length > 1) {
        setInitErrors(jResponse.messages);
      } else if (jResponse.message) {
        setInitErrors([jResponse.message]);
      } else {
        setInitErrors(['Server is unavailable at this time, please try again later.']);
      }
    } else {
      setResponse(jResponse);
    }
  };

  React.useEffect(() => {
    makeRequest();
  }, []);

  React.useEffect(() => {
    setApplyDisabled(false);
  }, [
    filters.uuid, filters.appId, filters.email,
  ]);

  const renderTableRows = () => {
    if (!response.map) {
      setTableRows([]);
      return;
    }
    const rows = response.map((record) => {
      let dateTimeString = '';
      if (record.timestamp) {
        const dateTime = new Date(record.timestamp);
        dateTimeString = `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
      }

      const standaloneMode = Config.getApplicationMode() === Config.ApplicationModes.Archive;
      let loadingPrecondition = !standaloneMode && record.appId && record.status === 'success';
      if (['percentile', 'modeljson', 'sensitivity'].includes(record.queue)) {
        // Can't different types of jobs as a case
        loadingPrecondition = false;
      }

      return {
        key: record.uuid,
        uuid: (
          <LinkButton
            text={record.uuid}
            onClick={async () => {
              dispatch(UiReducer.uiSetLoading(true));
              const fullRecord = await ArchivingApi.getRecord(record.uuid);
              if (fullRecord.responseStatus === 200) {
                setViewJson(fullRecord);
              } else {
                dispatch(UiReducer.uiSetErrors(fullRecord.messages));
              }
              dispatch(UiReducer.uiSetLoading(false));
            }}
          />
        ),
        actions: (
          loadingPrecondition && record.hasResults
            ? (
              <Actions record={record} />
            )
            : ''
        ),
        timestamp: dateTimeString,
        status: (<span>{record.status}</span>),
        user: (
          record.user
            ? (
              <LinkButton
                text={record.user}
                onClick={() => {
                  updateFilters('email', record.user);
                }}
              />
            )
            : ''
        ),
        appId: (
          record.appId
            ? (
              <LinkButton
                text={record.appId}
                onClick={() => {
                  updateFilters('appId', record.appId);
                }}
              />
            )
            : ''
        ),
        data: <Data record={record} loadingPrecondition={loadingPrecondition} />,
        recordType: <span>{typeLabelMap[record.queue]}</span>,
        version: (
          <table>
            <tbody>
              <tr>
                <td>core: </td>
                <td>{record.version?.['com-agenarisk-core']}</td>
              </tr>
              <tr>
                <td>api: </td>
                <td>{record.version?.['com-agenarisk-api']}</td>
              </tr>
            </tbody>
          </table>
        ),
      };
    });
    setTableRows(rows);
  };

  React.useEffect(() => {
    setTotalCount(response?.[0]?.totalCount || 0);
    renderTableRows();
  }, [response, showDetails]);

  if (initErrors) {
    return (<PlaceholderError messages={initErrors} />);
  }

  const tableColumns = [
    {
      key: 'column0', fieldName: 'uuid', name: 'Record ID', minWidth: 240, maxWidth: 240, isResizable: true,
    },
    {
      key: 'column-actions', fieldName: 'actions', name: '', minWidth: 20, maxWidth: 20,
    },
    {
      key: 'column1', fieldName: 'timestamp', name: 'Time Stamp', minWidth: 110, maxWidth: 110,
    },
    {
      key: 'column2', fieldName: 'status', name: 'Status', minWidth: 60, maxWidth: 60,
    },
    {
      key: 'column3', fieldName: 'user', name: 'User', minWidth: 200, maxWidth: 200, isResizable: true,
    },
  ];

  if (!fixedFilters?.appId) {
    tableColumns.push({
      key: 'column4', fieldName: 'appId', name: 'App ID', minWidth: 240, maxWidth: 240, isResizable: true,
    });
  }

  tableColumns.push({
    key: 'column5', fieldName: 'data', name: 'Data', minWidth: 80, maxWidth: 80,
  });

  if (extraFields) {
    tableColumns.push({
      key: 'column5-1', fieldName: 'recordType', name: 'Type', minWidth: 80, maxWidth: 80,
    });
  }

  if (showDetails) {
    tableColumns.push({
      key: 'column6', fieldName: 'version', name: 'Version', minWidth: 150, maxWidth: 150,
    });
  }

  return (
    <Stack vertical tokens={StackTokens.spacing} className={componentStyles.root}>

      <Stack horizontal tokens={StackTokens.spacing}>
        <SearchBox
          placeholder="Record ID"
          type="search"
          iconProps={{
            iconName: 'Filter',
          }}
          onChange={(ev, newValue) => updateFilters('uuid', newValue || '')}
          onClear={() => { updateFilters('uuid', ''); }}
          onEscape={() => { updateFilters('uuid', ''); }}
          onSearch={() => { makeRequest(); }}
          value={filters.uuid}
          className={componentStyles.filterBox}
        />

        { !fixedFilters?.appId && (
        <SearchBox
          placeholder="App ID"
          type="search"
          iconProps={{
            iconName: 'Filter',
          }}
          onChange={(ev, newValue) => updateFilters('appId', newValue || '')}
          onClear={() => { updateFilters('appId', ''); }}
          onEscape={() => { updateFilters('appId', ''); }}
          onSearch={() => { makeRequest(); }}
          value={filters.appId}
          className={componentStyles.filterBox}
        />
        )}

        <SearchBox
          placeholder="End-user email"
          type="search"
          iconProps={{
            iconName: 'Filter',
          }}
          onChange={(ev, newValue) => updateFilters('email', newValue || '')}
          onClear={() => { updateFilters('email', ''); }}
          onEscape={() => { updateFilters('email', ''); }}
          onSearch={() => { makeRequest(); }}
          value={filters.email}
          className={componentStyles.filterBox}
        />

        <PrimaryButton
          text="Apply"
          disabled={isApplyDisabled}
          onClick={() => { makeRequest(); }}
        />

        <DefaultButton
          text="Clear"
          onClick={() => {
            document.activeElement.blur();
            makeRequest({ ...filtersDefault });
          }}
        />

      </Stack>

      <Stack horizontal tokens={StackTokens.spacing}>
        <Checkbox
          label="Show versioning"
          checked={showDetails}
          className={Theme.styles.inputItem}
          onChange={async () => {
            setShowDetails(!showDetails);
          }}
        />
      </Stack>

      {
        (isLoading || tableRows.length > 0) ? (
          <ShimmeredDetailsList
            items={tableRows}
            columns={tableColumns}
            selectionMode={SelectionMode.none}
            className={componentStyles.detailsList}
            enableShimmer={isLoading}
          />
        ) : (
          <MessageBar
            text="No records found"
            onDismiss={null}
          />
        )
      }

      {
        !isLoading && (
          <Stack horizontal tokens={StackTokens.spacing}>

            <Pagination
              makeRequest={makeRequest}
              filters={filters}
              totalCount={totalCount}
              itemsInPage={response.length || 0}
            />

            {
            totalCount > 1 && (
              <Text
                style={{
                  paddingTop: '2px',
                }}
              >
                {totalCount}
                {' '}
                records found
              </Text>
            )
          }

          </Stack>
        )
      }

      <JsonViewer
        json={viewJson}
        setJson={setViewJson}
      />

    </Stack>
  );
};
