import { Stack } from '@fluentui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Store from '../shared/state/Store';
import HomeItemSection from './home/HomeItemSection';
import CreatorKeyAvailableHint from './home/CreatorKeyAvailableHint';
import CreatorKeyAvailableMessage from './home/CreatorKeyAvailableMessage';
import StackTokens from '../shared/StackTokens';
import Routing from '../shared/Routing';
import Env from '../shared/Env';

export default () => {
  const [globalState] = Store.useStore();
  const navigate = useNavigate();

  const routingTree = Routing.getTree(globalState).home.routes;

  const menuItems = [
    {
      type: 'tileButton',
      text: routingTree.administration.text,
      key: routingTree.administration.path,
      icon: 'Settings',
      description: 'Change configuration of this Portal; only visible to Portal admins',
      action: () => navigate(routingTree.administration.path),
      skip: routingTree.administration.skip,
    },
    {
      type: 'tileButton',
      text: routingTree.account.text,
      key: routingTree.account.path,
      icon: 'AccountManagement',
      description: 'Assign license keys and manage your Cloud Account subscription',
      action: () => navigate(routingTree.account.path),
      overlay: <CreatorKeyAvailableHint />,
    },
    {
      type: 'tileButton',
      text: routingTree.modeller.text,
      key: routingTree.modeller.path,
      icon: 'GitGraph',
      disabled: routingTree.modeller.disabled(globalState),
      description: 'View and calculate agena.ai models',
      action: () => navigate(routingTree.modeller.path),
      skip: !Env.FEATURE_MODELLER,
    },
    {
      type: 'tileButton',
      text: routingTree.webAppDesigner.text,
      key: routingTree.webAppDesigner.path,
      icon: 'EditPhoto',
      disabled: routingTree.webAppDesigner.disabled(globalState),
      description: 'Create publishable web apps from your agena.ai models',
      action: () => navigate(routingTree.webAppDesigner.path),
    },
    {
      type: 'tileButton',
      text: routingTree.cloudAppManager.text,
      key: routingTree.cloudAppManager.path,
      icon: 'ServerProcesses',
      disabled: routingTree.cloudAppManager.disabled(globalState),
      description: 'Manage and publish web apps you saved to your Cloud account, assign users and contributors',
      action: () => navigate(routingTree.cloudAppManager.path),
    },
    {
      type: 'tileButton',
      text: routingTree.apiServices.text,
      key: routingTree.apiServices.path,
      icon: 'Design',
      disabled: routingTree.apiServices.disabled(globalState),
      description: 'Use agena.ai cloud API as part of server-to-server pipeline; and for background calculations in your own web apps or interfaces',
      action: () => navigate(routingTree.apiServices.path),
    },
  ];

  return (
    <Stack horizontalAlign="center">
      <Stack
        vertical
        horizontalAlign="center"
        tokens={StackTokens.spacing}
        style={{
          width: 'fit-content',
        }}
      >
        <CreatorKeyAvailableMessage />
        <Stack
          horizontal
          horizontalAlign="center"
          tokens={{
            childrenGap: '20 10',
          }}
          style={{
            width: 'fit-content',
          }}
          wrap
        >
          {
          menuItems.filter((itemData) => itemData.skip !== true).map(((itemData) => (
            <HomeItemSection key={itemData.key} itemData={itemData} />
          )))
        }
        </Stack>
      </Stack>
    </Stack>
  );
};
