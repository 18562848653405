import React, { useState } from 'react';
import {
  Stack, PrimaryButton, mergeStyleSets, TextField,
} from '@fluentui/react';
import CollapsiblePanel from '../../../shared/CollapsiblePanel';
import StackTokens from '../../../shared/StackTokens';
import Store from '../../../shared/state/Store';
import UiReducer from '../../../shared/state/UiReducer';
import ConfigReducer from '../../../shared/state/ConfigReducer';
import AppReducer from '../../../shared/state/AppReducer';
import PlaceholderError from '../../../shared/PlaceholderError';

const styles = mergeStyleSets({
  button: {
    minWidth: '154px',
  },
});

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const [modelJsonString, setModelJsonString] = useState(JSON.stringify(globalState.config?.model, null, 2));
  const [modelJsonStringChanged, setModelJsonStringChanged] = useState(false);

  React.useEffect(() => {
    setModelJsonString(JSON.stringify(globalState?.config?.model, null, 2));
  }, [globalState.config?.model]);

  if (!AppReducer.isAppLoaded(globalState)) {
    return <PlaceholderError message="No app is currently loaded" />;
  }

  return (
    <Stack style={{ marginTop: '10px' }}>
      <Stack vertical>

        <CollapsiblePanel
          title="Edit model JSON manually"
          description="This feature is available as preview and does not validate model format"
          collapsible
          collapsed
        >
          <Stack vertical tokens={StackTokens.spacing}>
            <TextField
              label="Model JSON"
              multiline
              rows={10}
              value={modelJsonString}
              onChange={(event) => {
                setModelJsonString(event.target.value);
                setModelJsonStringChanged(true);
              }}
            />

            <span>
              <PrimaryButton
                text="Apply Changes"
                onClick={() => {
                  try {
                    const json = JSON.parse(modelJsonString);
                    dispatch(ConfigReducer.setModel(json.model || json));
                    setModelJsonStringChanged(false);
                    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppDesignerModelModified, true));
                  } catch (error) {
                    dispatch(UiReducer.uiSetErrors(['Failed to update model JSON from manual edit:', error.message]));
                  }
                }}
              // We can use this later to validate user input
              // onClick={async () => {
              //   updateModelJson({ name: 'manual.json' }, modelJsonString);
              // }}
                disabled={!modelJsonStringChanged}
                className={styles.button}
              />
            </span>

          </Stack>
        </CollapsiblePanel>
      </Stack>
    </Stack>
  );
};
