/* eslint-disable no-unused-expressions */ // Optional chaining
import DataReducer from './DataReducer';
import FormReducer from './FormReducer';
import UiReducer from './UiReducer';

const loadCase = (dispatch, caseData) => {
  if (caseData.inputData) {
    dispatch(FormReducer.setFormData(caseData.inputData));
  }

  if (caseData.inputCache) {
    if (caseData.inputCache.updatedColumnsMap) {
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptColumns, caseData.inputCache.updatedColumnsMap));
    }
    if (caseData.inputCache.updatedTableMap) {
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptMap, caseData.inputCache.updatedTableMap));
    }
  }

  if (Object.keys(caseData.outputData).length !== 0) {
    dispatch(DataReducer.cacheResults(DataReducer.DefaultCaseNames.USER, caseData.outputData));
  }
};

const validateCaseData = (caseData) => {
  const errors = [];
  ['inputData', 'outputData'].forEach((fieldName) => {
    if (!caseData[fieldName]) {
      errors.push(`Missing field: ${fieldName}`);
    }
  });

  if (errors.length > 0) {
    const error = new Error('Invalid case data');
    error.messages = errors;
    throw error;
  }
  return true;
};

const saveCase = (globalState) => {
  const inputData = FormReducer.getFormData(globalState);
  const outputData = DataReducer.getCachedResultsForDataSetRaw(globalState, DataReducer.DefaultCaseNames.USER) || {};
  const inputCache = {
    updatedColumnsMap: UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptColumns),
    updatedTableMap: UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap),
  };
  const contents = { inputData, outputData, inputCache };
  return contents;
};

const loadCaseFromRecordData = (dispatch, recordData) => {
  const fRecordData = { ...recordData };

  let tableDataFound = false;
  let columnDataFound = false;
  const updatedTableMap = {};
  if (fRecordData.inputs?.tableOverrides?.updatedTableMap) {
    fRecordData.inputs?.tableOverrides?.updatedTableMap.forEach((mapItem) => {
      updatedTableMap[mapItem.key] = mapItem.content;
      tableDataFound = true;
    });
  }

  const updatedColumnsMap = {};
  if (fRecordData.inputs?.tableOverrides?.updatedColumnsMap) {
    fRecordData.inputs?.tableOverrides?.updatedColumnsMap.forEach((mapItem) => {
      updatedColumnsMap[mapItem.key] = mapItem.content;
      columnDataFound = true;
    });
  }

  const formDataMap = {};
  if (fRecordData.inputs?.formDataMap) {
    fRecordData.inputs?.formDataMap.forEach((mapItem) => {
      formDataMap[mapItem.key] = mapItem.content;
    });
  }

  delete (fRecordData.inputs);

  const caseData = {
    inputData: formDataMap,
    ...((tableDataFound || columnDataFound) && {
      inputCache: {
        ...(tableDataFound && { updatedTableMap }),
        ...(columnDataFound && { updatedColumnsMap }),
      },
    }),
    outputData: fRecordData,
  };

  loadCase(dispatch, caseData);
};

export default {
  loadCase,
  validateCaseData,
  loadCaseFromRecordData,
  saveCase,
};
