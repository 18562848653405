import React from 'react';
import InputConfirmationDialog from '../../shared/InputConfirmationDialog';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import Utils from '../../shared/Utils';
import GraphUtils from '../GraphUtils';
import ModellerDefaults from '../ModellerDefaults';
import ModellerReducer from '../ModellerReducer';
import ModelProperties from './ModelProperties';
import SummaryStatsMarginals from './SummaryStatsMarginals';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const nodeData = UiReducer.getGenericUiAttribute(globalState, ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey) || {};

  // dispatch(UiReducer.setGenericUiAttribute(ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey, { datasetId, networkId, nodeId }));
  return (
    <>
      <InputConfirmationDialog
        uiElementId={ModellerDefaults.ComponentKeys.ModellerDataEntryPopupKey}
        title="Enter data"
        text={`Enter data for node "${Utils.createFriendlyReference(nodeData.nodeId, nodeData.nodeName)}" in network "${Utils.createFriendlyReference(nodeData.networkId, nodeData.networkName)}"`}
        inputPlaceholder="Value"
        primaryButtonText="Submit"
        hideOnAction
        validateValue={(val) => {
          const value = val.trim();
          if (value === '') {
            return value;
          }

          let validatedValue;
          try {
            validatedValue = GraphUtils.validateInputValue({
              value,
              validationRules: { type: (nodeData.nodeType === 'IntegerInterval') ? 'int' : 'float' },
            });
          } catch (error) {
            throw new Error(error.message);
          }
          return validatedValue;
        }}
        primaryCallback={async (value) => {
          dispatch(ModellerReducer.setObservation(nodeData.datasetId, nodeData.networkId, nodeData.nodeId, value));
        }}
      />

      <ModelProperties />
      <SummaryStatsMarginals />
    </>
  );
};
