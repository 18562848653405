import React from 'react';
import {
  TextField, Dropdown, Stack, mergeStyleSets, ImageFit, Image, DefaultButton, Text, Icon, Label,
} from '@fluentui/react';
import { isMobile } from 'react-device-detect';
import structuredClone from 'core-js/stable/structured-clone';
import Utils from '../../shared/Utils';
import Store from '../../shared/state/Store';
import FormReducer from '../../shared/state/FormReducer';
import UiReducer from '../../shared/state/UiReducer';
import ChartDefaults from '../../shared/ChartDefaults';
import Theme from '../../shared/Theme';
import Popup from '../../shared/Popup';
import Table from '../../shared/npt/Table';
import StackTokens from '../../shared/StackTokens';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import TableData from '../../shared/npt/TableData';

const DEFAULT_OPTION = "Don't know";

const style = mergeStyleSets({
  iconContainer: {
    width: '80px',
    height: '80px',
    float: 'left',
    paddingRight: '5px',
  },

  inputContainerWhenIcon: {
    paddingLeft: '80px',
  },

  inputFormItem: {
    'min-width': '160px',
    padding: '5px',
    border: '1px solid rgb(237, 235, 233)',
  },

  inputLabel: {
    fontSize: 'smaller',
  },

  inputLabelButton: {
    marginTop: '2px',
  },

  tableEditedIcon: {
    fontSize: '10px !important',
  },

  semanticEditorQuestionRow: {
    maxWidth: '70px',
  },

  inputItemQuestionnaire: {
    padding: '0',
  },

});

export default ({ config }) => {
  const [globalState, dispatch] = Store.useStore();

  const fieldKey = Utils.createNetworkNodeKey(config.network, config.node);

  const setInput = (network, node, value) => {
    const newFormData = { ...FormReducer.getFormData(globalState) };
    const itemKey = Utils.createNetworkNodeKey(network, node);
    if (!value) {
      delete newFormData[itemKey];
    } else {
      newFormData[itemKey] = { node, network, selected: value };
    }
    dispatch(FormReducer.setFormData(newFormData));
  };

  const {
    label, network, node,
  } = config;
  const labelText = `${label}`;

  const [inputError, setInputError] = React.useState('');

  const validateInputValue = (event, value) => {
    let valid = true;

    const valueTrimmed = value.trim();

    if (config.validation) {
      const {
        type, min, max, regex,
      } = config.validation;

      if (regex && !valueTrimmed.match(regex)) {
        valid = false;
      }

      if (valueTrimmed !== '' && type === 'int') {
        const valueInt = parseInt(valueTrimmed, 10);
        valid = valueTrimmed.toString() === valueInt.toString();
        if (max && valueInt > parseInt(max, 10)) {
          valid = false;
        }
        if (min && valueInt < parseInt(min, 10)) {
          valid = false;
        }
      }

      if (value.trim() !== '' && type === 'float') {
        const valueFloat = parseFloat(valueTrimmed, 10);
        valid = valueTrimmed.toString() === valueFloat.toString();
        if (max && valueFloat > parseFloat(max, 10)) {
          valid = false;
        }
        if (min && valueFloat < parseFloat(min, 10)) {
          valid = false;
        }
      }
    }

    if (!valid) {
      setInputError(`Invalid value: ${value}`);
      return;
    }

    setInputError('');
    setInput(network, node, value);
  };

  const hasIcon = config.icon && config.icon !== '';

  const inputMode = config.inputMode || ((config.type === 'text') ? ChartDefaults.InputModes.NumericInput.key : ChartDefaults.InputModes.Dropdown.key);
  const observationEnabled = config.inputModeObservation === true || config.inputModeObservation === undefined;

  let dropDownOptions = [];
  if (config.options) {
    if (config.inputMode) {
      dropDownOptions = config.options;
    } else {
      // To account for apps created before v0.7.2
      dropDownOptions = config.options.map((key) => ({ key, text: Utils.capitalize(key) }));
    }
  }

  React.useEffect(() => {
    // Reset cached NPT once on Input original render (form is opened)
    const overriddenTableMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap) || {};
    if (!overriddenTableMap[fieldKey]) {
      overriddenTableMap[fieldKey] = structuredClone(config.editableNPT);
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptMap, overriddenTableMap));
    }
  }, []);

  const tableEditingEnabled = config.inputModeNpt || config.semanticTableEditing?.length > 0;
  const [tableEditorOpen, setTableEditorOpen] = React.useState(false);
  const [tableEdited, setTableEdited] = React.useState(false);
  const [semanticEditorOpen, setSemanticEditorOpen] = React.useState(false);

  const isTableEdited = () => {
    if (!config.editableNPT) {
      return false;
    }

    const editedNptData = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)?.[fieldKey];
    return TableData.isTableEdited(editedNptData, config.editableNPT);
  };

  React.useEffect(() => {
    setTableEdited(isTableEdited());
  }, [
    UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)?.[fieldKey],
  ]);

  let observationEntrySection = <span>Disabled</span>;
  if (observationEnabled) {
    if (inputMode === ChartDefaults.InputModes.NumericInput.key) {
      observationEntrySection = (
        <TextField
          placeholder={config.placeholder}
          onChange={validateInputValue}
          value={FormReducer.getFormItemValue(globalState, fieldKey)?.selected || ''}
          errorMessage={inputError}
        />
      );
    }

    if (inputMode === ChartDefaults.InputModes.Dropdown.key) {
      observationEntrySection = (
        <Dropdown
          placeholder={DEFAULT_OPTION}
          selectedKey={FormReducer.getFormItemValue(globalState, fieldKey)?.selected || ''}
          options={[{ key: '', text: DEFAULT_OPTION }, ...dropDownOptions]}
          onChange={(event, item) => {
            setInput(network, node, item.key);
          }}
        />
      );
    }
  } else {
    // Observations are not enabled, probably can show a button to edit table or semantic edit
    if (config.inputModeNpt === 'allowWholeNptEditing') {
      observationEntrySection = (
        <DefaultButton
          className={Theme.styles.outlineControlButton}
          text="Edit Table"
          iconProps={{ iconName: 'Table' }}
          onClick={() => {
            setTableEditorOpen(true);
          }}
        />
      );
    }

    if (config.inputModeNpt === 'allowSemanticNptEditing') {
      observationEntrySection = (
        <DefaultButton
          className={Theme.styles.outlineControlButton}
          text="Edit Table"
          iconProps={{ iconName: 'Questionnaire' }}
          onClick={() => {
            setSemanticEditorOpen(true);
          }}
        />
      );
    }
  }

  const semanticEditorValidate = (value) => {
    const validatedValue = Number.parseFloat(value);

    if (validatedValue < 0) {
      throw new Error('Value must a positive number of zero');
    }

    if (validatedValue > 0 && validatedValue < TableData.DEFAULT_MIN_VALUE) {
      throw new Error(`Value must be bigger than ${TableData.DEFAULT_MIN_VALUE}`);
    }

    if (validatedValue > TableData.DEFAULT_MAX_VALUE) {
      throw new Error(`Value must be smaller than ${TableData.DEFAULT_MAX_VALUE}`);
    }

    // Allow NaN, we will treat this as removing override from the cell

    return validatedValue;
  };

  const semanticEditorApply = (value, questionData) => {
    const updatedTableMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap);
    const updatedTable = structuredClone(updatedTableMap[fieldKey]);

    const updatedColumnsMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptColumns) || {};
    const updatedColumns = structuredClone(updatedColumnsMap[fieldKey] || []);
    if (updatedColumns[questionData.col] === undefined) {
      updatedColumns[questionData.col] = [];
    }
    if (Number.isNaN(value) || value === undefined || value === null) {
      delete updatedColumns[questionData.col][questionData.row];
    } else {
      updatedColumns[questionData.col][questionData.row] = value;
    }

    let columnData = [];
    let undefinedCellsCount = 0;
    let overrideSum = 0;
    let overrideSumOthers = 0;
    let overriddenCount = 0;
    for (let rowIndex = 0; rowIndex < config.editableNPT.rows.length; rowIndex += 1) {
      // Check whether no cells in the column are being overridden
      const aVal = updatedColumns[questionData.col][rowIndex];
      if (aVal === undefined || aVal === null) {
        undefinedCellsCount += 1;
        columnData[rowIndex] = 0;
      } else {
        columnData[rowIndex] = aVal;
        overrideSum += aVal;
        if (rowIndex !== questionData.row) {
          overrideSumOthers += aVal;
        }
        overriddenCount += 1;
      }

      // updatedTable.rows[rowIndex][questionData.col] = config.editableNPT.rows[rowIndex][questionData.col];
    }

    if (config.semanticEditingStrict && overrideSum > 1) {
      throw new Error(`Value too large, only ${Utils.math(1 - overrideSumOthers)} is available`);
    }

    const resetColumn = (undefinedCellsCount === config.editableNPT.rows.length);

    if (resetColumn) {
      for (let rowIndex = 0; rowIndex < config.editableNPT.rows.length; rowIndex += 1) {
        updatedTable.rows[rowIndex][questionData.col] = config.editableNPT.rows[rowIndex][questionData.col];
      }
    } else {
      let fillerValue;
      if (overrideSum >= 1) {
        // Sum of provided values is >= 1, normalize then set non-overridden values to TableData.DEFAULT_MIN_VALUE
        columnData = TableData.normalize(columnData);
        fillerValue = config.semanticEditingStrict ? 0 : TableData.DEFAULT_MIN_VALUE;
      } else {
        // Sum is < 1, so we figure out other values
        // Avoid divison by zero even though should not happen here
        const fillerCount = updatedTable.rows.length - overriddenCount;
        fillerValue = Utils.math((1 - overrideSum) / (Math.max(1, fillerCount)));
      }

      for (let rowIndex = 0; rowIndex < columnData.length; rowIndex += 1) {
        const cellOverridden = updatedColumns[questionData.col][rowIndex] !== undefined && updatedColumns[questionData.col][rowIndex] !== null;
        updatedTable.rows[rowIndex][questionData.col] = cellOverridden ? columnData[rowIndex] : fillerValue;
      }

      // setTableEdited(true);
    }

    updatedTableMap[fieldKey] = updatedTable;
    updatedColumnsMap[fieldKey] = updatedColumns;
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptColumns, updatedColumnsMap));
    dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptMap, updatedTableMap));
  };

  // We can hide the main input if observation mode is disabled, semantic editing is enabled and semantic spread is also enabled
  let mainInputHidden = config.inputModeObservation === false && config.inputModeNpt === 'allowSemanticNptEditing' && config.semanticQuestionnaireSpread;
  mainInputHidden = mainInputHidden || (config.inputModeObservation === false && !config.inputModeNpt);

  return (
    <>
      <Stack
        horizontal
        grow={!config.preventStretch || isMobile}
        className={[style.inputFormItem, mainInputHidden ? Theme.styles.displayHidden : ''].join(' ')}
      >
        {hasIcon && (
        <div
          className={style.iconContainer}
        >
          <Image
            src={config.icon}
            imageFit={ImageFit.contain}
            maximizeFrame
          />
        </div>
        )}

        <Stack
          vertical
          styles={{ root: { width: '100%' } }}
          tokens={StackTokens.spacing}
        >
          <Stack
            horizontal
            tokens={StackTokens.horizontalSpacing}
          >
            <b className={style.inputLabel}>{labelText}</b>
            <Stack grow={10} />
            <Stack horizontalAlign="end">
              { tableEditingEnabled && (
              <DefaultButton
                text={tableEdited ? <Icon iconName="EditSolid12" className={style.tableEditedIcon} /> : ''}
                iconProps={{
                  iconName: 'Table',
                }}
                title="Table Override"
                className={[Theme.styles.iconButton, Theme.styles.outlineControlButton, style.inputLabelButton].join(' ')}
                styles={{
                  icon: { float: 'left' },
                  menuIcon: { display: 'none' },
                  textContainer: {
                    position: 'absolute',
                    left: '5px',
                    top: '5px',
                  },
                  label: tableEdited ? { color: 'red' } : { display: 'none' },
                }}
                menuProps={{
                  className: Theme.styles.outlineControlListButton,
                  onRestoreFocus: () => {},
                  items: [
                    ...((config.inputModeNpt === 'allowWholeNptEditing') ? [{
                      key: 'edit_table',
                      text: 'Edit Table',
                      iconProps: { iconName: 'Table' },
                      onClick: () => {
                        setTableEditorOpen(true);
                      },
                    }] : []),
                    ...((config.inputModeNpt === 'allowSemanticNptEditing' && config.semanticTableEditing?.length > 0) ? [{
                      key: 'show_questions',
                      text: 'Custom Probabilities',
                      iconProps: { iconName: 'Questionnaire' },
                      onClick: () => {
                        setSemanticEditorOpen(true);
                      },
                    }] : []),
                    {
                      key: 'reset_table',
                      text: 'Reset to Default',
                      iconProps: { iconName: 'DeleteTable' },
                      onClick: () => {
                        const updatedTableMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap);
                        updatedTableMap[fieldKey] = structuredClone(config.editableNPT);
                        dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptMap, updatedTableMap));

                        const updatedColumnsMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptColumns) || {};
                        delete updatedColumnsMap[fieldKey];
                        dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptColumns, updatedColumnsMap));
                        // setTableEdited(false); // xxx
                      },
                    },
                  ],
                }}
              />
              )}

              <Popup
                isOpen={tableEditorOpen}
                setIsOpen={setTableEditorOpen}
                title={`Edit probability table for ${config.label}`}
                showFooter
                showXButton
              >
                <Stack vertical tokens={StackTokens.spacing}>
                  <div>
                    <Text block>Click on a probability value to edit.</Text>
                    <Text style={{ maxWidth: '300px' }} block>Probabilities in a column will be automatically normalised to sum up to 1 during calculation.</Text>
                  </div>
                  { UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)
                  && UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)[fieldKey] && (
                  <Table
                    selectionEnabled
                    rangeSelectionEnabled
                    data={UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)[fieldKey]}
                    updateTable={(updatedTableData) => {
                      const updatedTableMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap);
                      updatedTableMap[fieldKey] = structuredClone(updatedTableData);
                      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptMap, updatedTableMap));
                      // setTableEdited(true);// xxx
                    }}
                    editableValues
                    showColumnAdjuster
                  />
                  )}

                </Stack>
              </Popup>

              <Popup
                isOpen={semanticEditorOpen}
                setIsOpen={setSemanticEditorOpen}
                title={`Set custom probabilities for underlying table of ${config.label}`}
                showFooter
                showXButton
              >
                <Stack vertical tokens={StackTokens.spacing}>
                  <Stack vertical tokens={StackTokens.spacing} style={{ maxWidth: '460px' }}>
                    {
                    (config.semanticTableEditingInstructions || 'Enter a value between 0 and 1 inclusive.').split('\n').map((line, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Text key={index} block>{line}</Text>
                    ))
                    }
                  </Stack>
                  { (config.semanticTableEditing || []).sort((q1, q2) => q1.col - q2.col + (q1.row - q2.row) / 10).map((questionData) => {
                    const tableDataMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap);
                    if (!tableDataMap || !tableDataMap[fieldKey]) {
                      return <div key={`${questionData.row}.${questionData.col}`} />;
                    }
                    const tableData = tableDataMap[fieldKey];

                    const updatedColumnsMap = structuredClone(UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptColumns) || {});
                    const updatedColumns = updatedColumnsMap[fieldKey] || [];

                    return (
                      <Stack horizontal key={`${questionData.row}.${questionData.col}`} tokens={StackTokens.spacing}>
                        <TextFieldBuffered
                          placeholder={updatedColumns[questionData.col]?.[questionData.row] || (config.semanticEditingHideNonOverridden ? '' : tableData.rows[questionData.row][questionData.col])}
                          actualValue={updatedColumns[questionData.col]?.[questionData.row] || (config.semanticEditingHideNonOverridden ? '' : tableData.rows[questionData.row][questionData.col])}
                          className={style.semanticEditorQuestionRow}
                          validateValue={semanticEditorValidate}
                          applyValue={(value) => {
                            semanticEditorApply(value, questionData);
                          }}
                        />
                        <Label>{questionData.question}</Label>
                      </Stack>
                    );
                  })}
                </Stack>
              </Popup>

            </Stack>
          </Stack>

          {observationEntrySection}

        </Stack>
      </Stack>

      { config.inputModeNpt === 'allowSemanticNptEditing' && config.semanticQuestionnaireSpread && (config.semanticTableEditing || []).map((questionData) => {
        const tableDataMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap);
        if (!tableDataMap || !tableDataMap[fieldKey]) {
          return <div key={`${fieldKey}.${questionData.row}.${questionData.col}`} />;
        }
        const tableData = tableDataMap[fieldKey];

        const columnsMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptColumns) || {};
        const columns = columnsMap[fieldKey] || [];

        const cellEdited = columns[questionData.col]?.[questionData.row] !== undefined && columns[questionData.col]?.[questionData.row] !== null;

        const computedValue = columns[questionData.col]?.[questionData.row] || tableData.rows[questionData.row][questionData.col];

        return (
          <Stack
            vertical
            key={`${fieldKey}.${questionData.row}.${questionData.col}`}
            tokens={StackTokens.spacing}
            grow={!config.preventStretch || isMobile}
            className={style.inputFormItem}
          >
            <Stack horizontal tokens={StackTokens.spacing}>
              <Label className={style.inputItemQuestionnaire}>{questionData.question}</Label>
              <Stack grow={10} />
              { cellEdited && (
              <Stack horizontalAlign="end">
                <DefaultButton
                  iconProps={{
                    iconName: 'DeleteTable',
                  }}
                  title="Reset to default"
                  className={[Theme.styles.iconButton, Theme.styles.outlineControlButton, style.inputLabelButton].join(' ')}
                  styles={{
                    icon: { float: 'left' },
                    menuIcon: { display: 'none' },
                    textContainer: {
                      position: 'absolute',
                      left: '5px',
                      top: '5px',
                    },
                  }}
                  onClick={() => {
                    semanticEditorApply(undefined, questionData);
                  }}
                />
              </Stack>
              )}
            </Stack>
            <TextFieldBuffered
              placeholder={(config.semanticEditingHideNonOverridden && !cellEdited) ? '' : computedValue}
              actualValue={(config.semanticEditingHideNonOverridden && !cellEdited) ? '' : computedValue}
              validateValue={semanticEditorValidate}
              applyValue={(value) => {
                if (value === computedValue) {
                  return;
                }
                semanticEditorApply(value, questionData);
              }}
            />
          </Stack>
        );
      })}

      {/* <Stack>
        { UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)
                  && UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)[fieldKey] && (
                  <Table
                    editableValues
                    selectionEnabled
                    rangeSelectionEnabled
                    updateTable={(updatedTableData) => {
                      const updatedTableMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap);
                      updatedTableMap[fieldKey] = structuredClone(updatedTableData);
                      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppInputsOverriddenNptMap, updatedTableMap));
                    }}
                    data={UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)[fieldKey]}
                  />
        )}
      </Stack> */}

    </>
  );
};
