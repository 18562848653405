import ChartDefaults from './ChartDefaults';
import Utils from './Utils';

const compileSummaryStatistics = (
  datasetId,
  datasetNodeResult,
  config = {
    decimalPlaces: ChartDefaults.decimalPlaces,
  },
) => {
  const dsData = {
    name: datasetId,
    rows: [],
  };

  const customPercentilesConfigured = config.customPercentiles && datasetNodeResult.percentiles;
  let customPercentilesInsertIndex = -1;

  Object.keys(ChartDefaults.SummaryStats).forEach((statName, index) => {
    if (statName === 'variance') {
      customPercentilesInsertIndex = index;
    }
    if (customPercentilesConfigured && (statName === 'lowerPercentile' || statName === 'upperPercentile')) {
      return;
    }
    const statDisplayName = ChartDefaults.SummaryStats[statName].displayName;
    const rawValue = datasetNodeResult.summaryStatistics[statName];
    const value = Utils.roundFormat(rawValue, config.decimalPlaces, ChartDefaults.decimalPlaces);
    dsData.rows.push({ statistic: statDisplayName, value });
  });

  if (customPercentilesConfigured) {
    // We want to insert after Variance
    config.customPercentiles.forEach((cPercentile, i) => {
      const value = datasetNodeResult.percentiles[`${cPercentile}`];
      if (!value) {
        return;
      }
      const formattedValue = Utils.roundFormat(value, config.decimalPlaces, ChartDefaults.decimalPlaces);
      dsData.rows.splice(1 + customPercentilesInsertIndex + i, 0, { statistic: `${cPercentile}th percentile`, value: formattedValue });
    });
  }

  return dsData;
};

const compileChartDataRows = (
  datasetNodeResult,
  nodeType,
  config = {
    decimalPlaces: ChartDefaults.decimalPlaces,
  },
) => {
  const isIntervalNode = ['ContinuousInterval', 'IntegerInterval'].includes(nodeType);
  const integerOffset = (nodeType && nodeType === 'IntegerInterval') ? 1 : 0;
  const rows = [];

  if (!datasetNodeResult || !datasetNodeResult.resultValues) {
    return rows;
  }

  datasetNodeResult.resultValues.forEach((resultValue) => {
    const { label, value } = resultValue;

    let row;

    if (!isIntervalNode) {
      row = [
        label,
        Utils.roundFormat(value, config.decimalPlaces, ChartDefaults.decimalPlaces),
      ];
    } else {
      const isInterval = label.indexOf(' - ') >= 0;
      const rangeSplit = label.split(' - ');
      let lower = parseFloat(rangeSplit[0]);
      let upper = parseFloat(rangeSplit[1]);

      if (!isInterval) {
        upper = lower;
      }

      if (!Number.isFinite(lower)) {
        lower = Number.MAX_VALUE * ((lower < 0) ? -1 : 1);
      }

      if (!Number.isFinite(upper)) {
        upper = Number.MAX_VALUE * ((upper < 0) ? -1 : 1);
      }

      let pState = parseFloat(value);
      if (!Number.isFinite(value)) {
        pState = Number.MAX_VALUE * ((value < 0) ? -1 : 1);
      }

      let midPoint;
      if (isInterval) {
        midPoint = (lower + upper) / 2;
      } else {
        midPoint = lower;
      }
      const range = (upper - lower) + integerOffset;
      const density = (range > 0) ? pState / range : pState;

      row = [
        Utils.roundFormat(lower, config.decimalPlaces, ChartDefaults.decimalPlaces),
        Utils.roundFormat(upper, config.decimalPlaces, ChartDefaults.decimalPlaces),
        Utils.roundFormat(midPoint, config.decimalPlaces, ChartDefaults.decimalPlaces),
        Utils.roundFormat(pState, config.decimalPlaces, ChartDefaults.decimalPlaces),
        density.toPrecision(1 + ((config.decimalPlaces !== undefined) ? config.decimalPlaces : ChartDefaults.decimalPlaces)),
      ];
    }

    rows.push(row);
  });

  return rows;
};

const compileChartDataTable = (
  datasetId,
  nodeType,
  rows,
) => {
  const isIntervalNode = ['ContinuousInterval', 'IntegerInterval'].includes(nodeType);

  const dsTableData = {
    name: datasetId,
    headers: ['State', 'P(State)'],
    rows,
  };

  if (isIntervalNode) {
    dsTableData.headers = ['Lower Bound', 'Upper Bound', 'Midpoint', 'P(State)', 'Density'];
  }
  return dsTableData;
};

export default {
  compileSummaryStatistics,
  compileChartDataRows,
  compileChartDataTable,
};
