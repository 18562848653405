import React from 'react';
import { mergeStyleSets, Stack, Text } from '@fluentui/react';
import MultiLineUserText from '../../shared/MultiLineUserText';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import ConfigReducer from '../../shared/state/ConfigReducer';
import FormReducer from '../../shared/state/FormReducer';
import Utils from '../../shared/Utils';
import TableData from '../../shared/npt/TableData';
import Table from '../../shared/npt/Table';
import Theme from '../../shared/Theme';
import Legend from '../../shared/Legend';
import ChartDefaults from '../../shared/ChartDefaults';
import OutputGroup from '../Report/OutputGroup';
import PrintConfigDefaults from './PrintConfigDefaults';
import StackTokens from '../../shared/StackTokens';

export default ({
  visible, styles, titleSettingsButton,
}) => {
  const componentStyles = mergeStyleSets({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      minWidth: '100vw',
      height: 'fit-content',
      minHeight: '100vh',
      overflow: 'hidden',
      zIndex: 10000,
      paddingLeft: '5px',

      background: 'white',
      // background: 'lime',

      selectors: {
        '@media screen': {
          margin: '5px',
        },
        '&.png-charts .output-item-actions': {
          display: 'none !important',
        },
      },
    },

    inputsTable: {
      selectors: {
        '&>tbody>tr': {
          pageBreakInside: 'avoid',
        },
        '&>tbody>tr>td:first-child': {

        },
        '&>tbody>tr.odd>td': {
          background: '#ffffff',
        },
        '&>tbody>tr.even>td': {
          background: '#f2f2f2',
        },
        '&>tbody>tr>th': {
          fontWeight: '400 !important',
          fontSize: 'medium !important',
          padding: '5px 0 0 0 !important',
          textAlign: 'left',
        },
      },
    },

    outputsReport: {
      selectors: {
        '.output-item': {
          maxWidth: '100% !important',
          minWidth: 'fit-content !important',
          // display: 'block !important',

          // pageBreakBefore: 'always',
          pageBreakInside: 'avoid',
          breakInside: 'avoid',
        },

        '.output-item.summaryStatistics, .output-item.chartData, .output-item.rawData': {
          height: 'fit-content !important',
          width: 'fit-content !important',
          pageBreakInside: 'avoid',
          breakInside: 'avoid',
          selectors: {
            '.output-table': {
              height: 'fit-content !important',
              width: 'fit-content !important',
              overflow: 'visible',
            },
          },
        },
        '.output-item.rawData': {
          selectors: {
            '@media print': {
              scale: '0.85',
              transformOrigin: 'top left',
            },
            '.output-table': {
              // fontSize: 'calc(1vw)',
            },
          },
        },
        '.ms-Stack, .ms-Stack-inner': {
          display: 'block !important',
        },
        '.output-item-actions': visible ? {
          // Use these settings only when Print Report's visibility is controlled from params (archived record)
          position: 'relative',
          top: '-33px',
          left: '80%',
          height: '0px',
        } : {
          display: 'none !important',
        },
      },
    },

    header: {
      fontWeight: 500,
      fontSize: 'large',
      padding: '10px 0 0 0',
    },

    copyContent: {
      selectors: {
        '.svg-charts &': {
          display: 'none !important',
        },
      },
    },
  });

  const [globalState, dispatch] = Store.useStore();
  const { inputConfig, generalConfig } = ConfigReducer.getConfig(globalState);

  const showPrintReport = visible || UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppShowPrintReport);

  const printReport = React.useRef();

  React.useEffect(() => {
    setTimeout(() => {
      if (!printReport.current) {
        return;
      }
      const copyInProgress = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppPrintReportCopyRequested);

      if (!copyInProgress) {
        printReport.current.classList.add('svg-charts');
        printReport.current.classList.remove('png-charts');
        return;
      }

      printReport.current.classList.remove('svg-charts');
      printReport.current.classList.add('png-charts');
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppPrintReportCopyReady, true));
    }, 50);
  }, [
    UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppPrintReportCopyRequested),
  ]);

  React.useEffect(() => {
    setTimeout(() => {
      const copyReady = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppPrintReportCopyReady);
      if (!copyReady) {
        return;
      }

      Utils.copyContentsToClipboard(document.getElementById('printReport'));

      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppPrintReportCopyReady, false));
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppPrintReportCopyRequested, false));
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.webAppShowPrintReport, false));
    }, 50);
  }, [
    UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppPrintReportCopyReady),
  ]);

  if (!showPrintReport) {
    return <></>;
  }

  const printConfig = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppPrintReportConfig) || PrintConfigDefaults.appDefaults(globalState);

  document.title = printConfig.reportHeader || generalConfig.appName;

  const caseDataSummary = [];

  if (printConfig.showVersioning) {
    Object.entries(globalState.resultCache?.raw || {}).forEach(([key, caseData]) => {
      if (!caseData) {
        return;
      }

      const dMessages = (caseData?.debug || []);
      const lastDMessage = dMessages[dMessages.length - 1];

      const caseSummary = {
        case: key,
        components: [],
        timestamp: `${lastDMessage.time || ''}`.replace('T', ' '),
      };

      Object.entries(caseData.version || {}).forEach(([vKey, vValue]) => {
        caseSummary.components.push({
          component: vKey,
          version: vValue,
        });
      });

      caseDataSummary.push(caseSummary);
    });
  }

  return (
    <div
      className={[componentStyles.root, 'svg-charts'].join(' ')}
      id="printReport"
      ref={printReport}
      style={styles?.root}
    >
      <Stack vertical>
        <>
          <Stack horizontal>
            {titleSettingsButton && titleSettingsButton}
            <header className={componentStyles.header}>{printConfig.reportHeader}</header>
          </Stack>

          <br className={componentStyles.copyContent} />

          {
            printConfig.showAppDescription && (
            <MultiLineUserText>
              {generalConfig.appDescription}
            </MultiLineUserText>
            )
          }
        </>

        <>
          <header className={componentStyles.header}>{printConfig.inputSectionName}</header>

          <br className={componentStyles.copyContent} />

          <table className={componentStyles.inputsTable}>
            {
                inputConfig.map((group) => (
                  <tbody key={group.groupName}>

                    <tr>
                      <th colSpan={2}>{group.groupName}</th>
                    </tr>
                    {
                        group.items.map((question, index) => {
                          const rowClass = (index % 2 === 0) ? 'even' : 'odd';
                          const fieldKey = Utils.createNetworkNodeKey(question.network, question.node);
                          const label = `${question.label}`;
                          const answer = FormReducer.getFormItemValue(globalState, fieldKey)?.selected || printConfig.noValueString;

                          const tableEditing = {
                            enabled: question.inputModeNpt || question.semanticTableEditing?.length > 0,
                            defaultTable: question.editableNPT,
                            currentTable: UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)?.[fieldKey],
                            tableEdited: TableData.isTableEdited(
                                UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptMap)?.[fieldKey],
                                question.editableNPT,
                            ),
                            semanticEditingEnabled: question.inputModeNpt === 'allowSemanticNptEditing',
                            semanticQuestions: question.semanticTableEditing || [],
                          };

                          return (
                            <React.Fragment key={group.groupName + fieldKey}>
                              <tr className={rowClass}>
                                <td>{label}</td>
                                <td>{answer}</td>
                              </tr>
                              {
                                tableEditing.enabled && !tableEditing.semanticEditingEnabled && tableEditing.tableEdited && (
                                <tr className={[rowClass, Theme.styles.noPageBreak].join(' ')}>
                                  <td>Custom table</td>
                                  <td>
                                    <Table
                                      data={tableEditing.currentTable}
                                      optionsEnabled={false}
                                      showHeaderRow={false}
                                    />
                                  </td>
                                </tr>
                                )
                              }

                              {
                                tableEditing.enabled && tableEditing.semanticEditingEnabled && (
                                <>
                                    {
                                        tableEditing.semanticQuestions.map((questionData) => {
                                          const columnsMap = UiReducer.getGenericUiAttribute(globalState, UiReducer.Keys.webAppInputsOverriddenNptColumns) || {};
                                          const columns = columnsMap[fieldKey] || [];

                                          const cellEdited = columns[questionData.col]?.[questionData.row] !== undefined && columns[questionData.col]?.[questionData.row] !== null;

                                          const actualSQuestionValue = columns[questionData.col]?.[questionData.row];
                                          return (
                                            <tr className={[rowClass, Theme.styles.noPageBreak].join(' ')} key={questionData.question}>
                                              <td>{questionData.question}</td>
                                              <td>
                                                {
                                                    cellEdited ? actualSQuestionValue : printConfig.noValueString
                                                }
                                              </td>
                                            </tr>
                                          );
                                        })
                                    }
                                </>
                                )
                              }
                            </React.Fragment>
                          );
                        })
                    }
                  </tbody>

                ))
            }
          </table>
        </>

        <br className={componentStyles.copyContent} />

        <>
          <header className={componentStyles.header}>{printConfig.outputSectionName}</header>

          {
            !!ConfigReducer.getGeneralConfig(globalState).baselineEnabled
            && (
            <Legend
              series={{
                Baseline: ConfigReducer.getGeneralConfig(globalState).colorBaseline || ChartDefaults.ColorBaseline,
                User: ConfigReducer.getGeneralConfig(globalState).colorUser || ChartDefaults.ColorUser,
              }}
            />
            )
           }

          <br className={componentStyles.copyContent} />

          <Stack vertical className={componentStyles.outputsReport} tokens={StackTokens.spacing}>
            {
                ConfigReducer.getOutputConfig(globalState).map((group) => (
                  <OutputGroup
                    key={group.groupName}
                    config={group}
                  />
                ))
            }
          </Stack>
        </>

        {
            printConfig.showVersioning && (
            <Stack vertical className={[componentStyles.outputsReport, Theme.styles.noPageBreak].join(' ')} tokens={StackTokens.spacing}>
              <header className={componentStyles.header}>Versioning</header>

              <Text>Summary of server components at the time of calculation:</Text>

              <br className={componentStyles.copyContent} />

              {
                caseDataSummary.map((caseSummary) => (
                  <table className={componentStyles.inputsTable} key={caseSummary.case}>
                    <tbody>
                      <tr>
                        <th colSpan={2}>{caseSummary.case}</th>
                      </tr>
                      {
                        caseSummary.components.map((cData, i) => (
                          <tr key={cData.component} className={(i % 2 === 0) ? 'even' : 'odd'}>
                            <td>{cData.component}</td>
                            <td>{cData.version}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                ))
              }
            </Stack>
            )
        }

        {
            printConfig.showTimestamp && (
            <Stack vertical className={[componentStyles.outputsReport, Theme.styles.noPageBreak].join(' ')} tokens={StackTokens.spacing}>
              <header className={componentStyles.header}>Timestamp</header>

              <Text>Calculation time:</Text>

              <br className={componentStyles.copyContent} />

              <table className={componentStyles.inputsTable}>
                <tbody>
                  {
                    caseDataSummary.map((caseSummary, i) => (
                      <tr key={caseSummary.case} className={(i % 2 === 0) ? 'even' : 'odd'}>
                        <td>{caseSummary.case}</td>
                        <td>{caseSummary.timestamp}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </Stack>
            )
        }

      </Stack>
    </div>
  );
};
