const KC_ENABLED = `${window.env.REACT_APP_KC_ENABLED}`.toLowerCase() === 'true';
const API_ROOT = window.env.REACT_APP_API_ROOT || 'https://api.agena.ai';
const CLOUD_APP_DOMAIN_SUFFIX = (window.env.REACT_APP_CLOUD_APP_DOMAIN_SUFFIX || '').toLowerCase();
const CLOUD_APP_PUBLIC_DOMAIN_SUFFIX = (window.env.REACT_APP_CLOUD_APP_PUBLIC_DOMAIN_SUFFIX || '').toLowerCase();
const APPLICATION_MODE = (window.env.REACT_APP_APPLICATION_MODE || '').toLowerCase();
const CONFIG_LOCATION = window.env.REACT_APP_CONFIG_LOCATION;
const CLOUD_APP_DOMAIN_ALLOW_DOTS = `${window.env.REACT_APP_CLOUD_APP_DOMAIN_ALLOW_DOTS}`.toLowerCase() === 'true';
const REGISTER_URL = 'https://auth.agena.ai/realms/cloud/protocol/openid-connect/registrations?client_id=agena-ai-cloud&response_type=code&scope=openid email&redirect_uri=https://portal.agena.ai';
const MODE_STAGING = document.location.hostname.indexOf('staging') >= 0;
const MODE_DEV = document.location.hostname === 'localhost';
const FEATURE_MODELLER = `${window.env.REACT_APP_FEATURE_MODELLER}`.toLowerCase() === 'true';

export default {
  KC_ENABLED,
  API_ROOT,
  CLOUD_APP_DOMAIN_SUFFIX,
  CLOUD_APP_PUBLIC_DOMAIN_SUFFIX,
  APPLICATION_MODE,
  CONFIG_LOCATION,
  CLOUD_APP_DOMAIN_ALLOW_DOTS,
  REGISTER_URL,
  MODE_STAGING,
  MODE_DEV,
  FEATURE_MODELLER,
};
