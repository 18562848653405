import React from 'react';
import { PrimaryButton, Stack } from '@fluentui/react';
import Store from '../../shared/state/Store';
import UiReducer from '../../shared/state/UiReducer';
import Utils from '../../shared/Utils';
import tokens from '../../shared/StackTokens';
import MultiLineUserText from '../../shared/MultiLineUserText';
import CaseManager from '../../shared/state/CaseManager';
import ModellerReducer from '../../modeller/ModellerReducer';
import ModelUtils from '../../modeller/ModelUtils';

export default () => {
  const [globalState, dispatch] = Store.useStore();
  const browseButtonRef = React.useRef(null);

  const clickSelectFile = () => {
    if (browseButtonRef.current) {
      browseButtonRef.current.click();
    }
  };

  const getSelectedFile = (event) => {
    if (event.target.files?.length <= 0) {
      dispatch(UiReducer.uiSetErrors(['No file selected']));
      return false;
    }
    return event.target.files[0];
  };

  const loadJsonFromFile = async (file) => {
    const { size } = file;

    if (size > 104857600) {
      dispatch(UiReducer.uiSetErrors(['File size too big, max 100 Mb allowed']));
      return false;
    }

    const content = await Utils.readFileContents(file);
    let json;
    try {
      json = JSON.parse(content);
    } catch (error) {
      dispatch(UiReducer.uiSetErrors(['File does not contain valid JSON', error.message]));
      return false;
    }

    return json;
  };

  const resetFileBrowseButtonSelection = () => {
    if (browseButtonRef.current) {
      browseButtonRef.current.value = '';
    }
  };

  const validateJson = (json) => {
    try {
      return CaseManager.validateCaseData(json);
    } catch (error) {
      dispatch(UiReducer.uiSetErrors(error.messages || [error.message]));
      return false;
    }
  };

  const loadFile = async (event) => {
    const file = getSelectedFile(event);
    const json = await loadJsonFromFile(file);
    if (json && validateJson(json)) {
      CaseManager.loadCase(dispatch, json);
      dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.isCaseManagerPanelOpen, false));
    }
    resetFileBrowseButtonSelection();
  };

  return (
    <Stack vertical tokens={tokens.spacing}>
      <MultiLineUserText>You can save and load case data (both inputs and outputs) from file or your account</MultiLineUserText>

      <Stack horizontal tokens={tokens.spacing}>
        <span>
          <PrimaryButton
            text="Load Case"
            onClick={clickSelectFile}
          />
          <input ref={browseButtonRef} type="file" accept="application/json" onChange={loadFile} style={{ display: 'none' }} />
        </span>
        <span>
          <PrimaryButton
            text="Save Case"
            onClick={() => {
              const contents = CaseManager.saveCase(globalState);
              Utils.saveToFile('usecase.json', JSON.stringify(contents));
              dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.isCaseManagerPanelOpen, false));
            }}
          />
        </span>
      </Stack>

      { globalState.config?.generalConfig?.modelViewEnabled && (
        <>
          <MultiLineUserText>Copy current user case to model view (does not include adjusted prior probabilities):</MultiLineUserText>
          <Stack horizontal tokens={tokens.spacing}>
            <span>
              <PrimaryButton
                text="Copy Case"
                onClick={() => {
                  const updatedModel = Utils.clone(ModellerReducer.getModel(globalState));
                  const caseData = CaseManager.saveCase(globalState);
                  const responseData = caseData.outputData;

                  // Make sure we add a unique ID
                  let newCaseId;
                  const existingIds = updatedModel.dataSets.map((dataset) => dataset.id);
                  for (let i = 0; i < 1000; i += 1) {
                    if (newCaseId && !existingIds.includes(newCaseId)) {
                      break;
                    }
                    newCaseId = `Case ${updatedModel.dataSets.length + i}`;
                  }

                  // Create and push a new case
                  const newCase = {
                    id: newCaseId,
                    results: responseData.results || [],
                    observations: [],
                  };
                  updatedModel.dataSets.push(newCase);

                  // Add observations to now existing case
                  (Object.values(caseData.inputData || [])).forEach((obsData) => {
                    ModelUtils.setObservation({
                      model: updatedModel,
                      datasetId: newCaseId,
                      networkId: obsData.network,
                      nodeId: obsData.node,
                      value: obsData.selected,
                    });
                  });

                  // Update the Modeller's model and cache the raw associated response
                  dispatch(ModellerReducer.updateModel(updatedModel));
                  dispatch(ModellerReducer.cacheCalculationResponse(newCaseId, responseData));
                  dispatch(UiReducer.setGenericUiAttribute(UiReducer.Keys.GeneralSimpleMessageModal, {
                    title: 'Case copied',
                    message: `Case copied as "${newCaseId}"`,
                  }));
                }}
              />
            </span>
          </Stack>
        </>
      )}
    </Stack>
  );
};
