const NodeModes = {
  PLAIN: 'plain',
  CHART: 'chart',
};

const InputModes = {
  NUMERIC: 'numeric',
  DROPDOWN: 'dropdown',
  SLIDER: 'slider',
};

const InputDefaultOption = "Don't know";

const DefaultGraphics = {
  input: {},
  graph: {
    width: 200,
    height: 100,
  },
  chart: {
    decimalPlaces: 2,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
};

const FlatModelGraphKey = '___flat____model___';

const ComponentDefaults = {
  ComponentHeight: '82vh',
  ComponentHeightMultipler: 0.82,
  LeftWidth: '12vw',
  LeftWidthMultiplier: 0.12,
  LeftWidthMin: 130,
  RightWidth: '14vw',
  RightWidthMultiplier: 0.14,
  RightWidthMin: 230,
  MiddleWidth: '74vw',
  MiddleWidthMultiplier: 0.74,
  MinimisedWidth: 25,
};

const ComponentKeys = {
  ModellerDataEntryPopupKey: 'ModellerDataEntryPopupKey',
  ModellerModelPropertiesPopupKey: 'ModellerModelPropertiesPopupKey',
  ModellerResultsDialogKey: 'ModellerResultsDialogKey',
  ModellerAddCaseDialog: 'ModellerAddCaseDialog',
};

const OperationMode = {
  ModellerPortal: 'ModellerPortal',
  ModellerStandalone: 'ModellerStandalone',
  ModellerWebApp: 'ModellerWebApp',
};

const GraphDefaults = {
  ChartModeExtraHeight: 20,
};

export default {
  NodeModes,
  InputModes,
  InputDefaultOption,
  DefaultGraphics,
  FlatModelGraphKey,
  ComponentDefaults,
  ComponentKeys,
  OperationMode,
  GraphDefaults,
};
